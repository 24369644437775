import React from "react";
import i18n from "#translate/i18n";
import { Trans } from "react-i18next"
import CloseIcon from '#images/close-btn.svg';
import EditIcon from '#images/edit-icon-form.svg';
import NewIcon from '#images/new_icon_form.svg';
import userPresentedName from '#services/userPresentedName';

export default function CancelConfirmation({
  giveUpFunction,
  cancelFunction,
  userData,
  i18nTitle,
  i18nText,
  cancelButtonText,
  continueButtonText,
  iconName,
}) {
  const icons = {
    newUserForm: NewIcon,
    editUserForm: EditIcon
  }
  return (
    <div className='bg-black bg-opacity-30 fixed top-0 left-0 right-0 bottom-0 z-[1400] h-screen' >
      <div className='h-full w-full flex justify-center items-center' >
        <div className="bg-white z-[2000] w-[700px] h-60 p-6 absolute
                        shadow-[0px_8px_10px_0px_rgba(0,0,0,0.20),0px_6px_30px_0px_rgba(0,0,0,0.12),0px_16px_24px_0px_rgba(0,0,0,0.14)]"
        >
          <div className="flex flex-col items-center bg-white w-[640px]" >
            <button
              id='close-cancel-confirmation'
              className='flex p-2.5 justify-center items-center absolute right-3 top-6 mr-3'
              onClick={() => giveUpFunction()}
            >
              <img
                className={`w-3 h-3 min-w-max`}
                src={CloseIcon}
                alt="close button"
              />
            </button>
            <div className='flex p-[8px_0px_0px_0px] items-start gap-4 self-stretch' >
              <div className='flex w-14 h-14 justify-center items-center rounded-[28px] border-solid
                              border-[var(--error-50,#FEF3F2)] bg-[var(--error-100,#FEE4E2)]'
              >
                <img
                  src={icons[iconName]}
                  alt="event icon"
                />
              </div>
              <div className='flex flex-col justify-center items-start gap-1 flex-1' >
                <p className='text-black font-sans text-xl not-italic font-semibold' >
                  {i18n.t(`${i18nTitle}`)}
                </p>
                <p className='text-cyan-blue-medium-dark font-normal font-sans text-sm not-italic' >
                  <Trans
                    i18nKey={i18n.t(i18nText)}
                    values={{ name: userPresentedName(userData) }}
                  />
                </p>
              </div>
            </div>
          </div>
          <div className='p-[0px_24px_24px_24px] flex justify-end items-center gap-6 self-stretch absolute bottom-2 right-2' >
            <button
              className="justify-center font-sans font-semibold text-base bg-white
                        text-rebase-blue rounded flex items-center px-4 py-2.5"
              id="cancel-give-up-button"
              onClick={() => giveUpFunction()}
            >
              {continueButtonText}
            </button>

            <button
              className="justify-center items-center gap-2.5 py-2.5 px-4 flex rounded font-sans font-semibold text-base
                         bg-support-error text-white border w-[112px] h-11"
              id="cancel-confirmation-button"
              onClick={() => cancelFunction()}
            >
              {cancelButtonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
