import React from "react"
import { Trans } from "react-i18next"
import i18n from "#translate/i18n"

import SuccessCheck from "#images/bonus_drawer/success_check.svg"
import CloseButton from "#images/bonus_drawer/close_button.svg"

export default function SuccessMessage({ name, closeMessage, i18nText, i18nTitle, i18nName }) {
  const shownName = i18nName ? i18n.t(i18nName) : name
  return (
    <div className="rounded fixed w-[437px] h-32 right-10 bottom-8 shadow-[0_1px_2px_rgba(0,0,0,0.3),0_2px_6px_rgba(0,0,0,0.15)] bg-white z-[100]">
      <div className="flex flex-row w-full h-full">
        <div className="bg-teal-100 h-full w-24 flex flex-row items-center">
          <img src={SuccessCheck} alt={ i18n.t('successMessage.check') } className="mx-auto" />
        </div>

        <div className="grow p-4">
          <h1 className="font-semibold font-sans text-base">{ i18n.t(i18nTitle) }</h1>
          <p className="font-sans font-normal text-sm mt-1"><Trans i18nKey={i18nText} values={{ name: shownName }}/></p>
        </div>
      </div>

      <button onClick={closeMessage} className="w-5 h-11 absolute top-0 right-3">
        <img src={ CloseButton } alt={ i18n.t('drawerResponses.success.check') } />
      </button>
    </div>
  )
}