import React from "react";
import { useFormContext } from "react-hook-form";
import i18n from "#translate/i18n";

export default function CustomizedFieldOption({ option, prefix }) {
  const { register, setValue } = useFormContext();

  return (
    <>
      <div className="h-10 w-10">
        <input
          {...register(`${prefix}.${option}`)}
          id={`${prefix}_${option}_input`}
          type="checkbox"
          className="w-4 h-4 rounded border-slate-300 ml-2.5 mb-1"
          onChange={(e) => {
            setValue(`${prefix}.${option}`, e.target.checked);
          }}
        />
      </div>
      <p>{i18n.t(`customizedDataExport.selections.sections.${prefix}.${option}`)}</p>
    </>
  );
}