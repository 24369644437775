import React from 'react';
import { Pie } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import i18n from "#translate/i18n";

export default function AllowancesDistribution({remunerationsData}) {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const monthlyTotal = Object.values(remunerationsData).map((remuneration) => Number(remuneration.sum_allowances))
  const totalSum = monthlyTotal.reduce((accumulator, currentValue) => accumulator + currentValue, 0,)
  const xValues = ['healthcare', 'year_end_bonus', 'vacation', 'meal', 'home_office']
  const yValues = () => {
    const sum = xValues.map((allowance) => {
      const values = Object.values(remunerationsData).map((monthlyData) => Number(monthlyData[`sum_${allowance}`]))
      const allowanceTotal = values.reduce((accumulator, currentValue) => accumulator + currentValue, 0,)
      return ((allowanceTotal/totalSum) * 100)
    })
    return sum
  }

  const colors = ['#F7A23B', '#F75D5F', '#00C7F2', '#FBC62F', '#0FCA7A']
  const data = {
    labels: xValues.map((allowance) =>  i18n.t(`homePage.remunerations.allowances.${allowance}`)),
    datasets: [
      {
        data: yValues(),
        backgroundColor: colors,
        borderWidth: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        align: "start",
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          boxWidth: 10,
          boxHeight: 10,
          padding: 30,
          fullSize: true,
          color: '#2D2D2D'
        },
        onClick: (e) => e.stopPropagation()
      },
      tooltip: {
        callbacks: {
          label: function(tooltipItem) {
            const value = tooltipItem.raw;
            return `${tooltipItem.label}: ${Number(value).toFixed(2)}%`;
          }
        }
      }
    },
    layout: {
      padding: {
        top: -15,
        bottom: 20,
      },
    },
    animation: {
      onComplete: (animation) => {
        const chartInstance = animation.chart;
        const ctx = chartInstance.ctx;
        ctx.font = '500 12px Inter';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        chartInstance.data.datasets[0].data.forEach((value, i) => {
          const meta = chartInstance.getDatasetMeta(0);
          const element = meta.data[i];
          if (!element || !element.circumference || value <= 0) return;
          const { x, y } = element.tooltipPosition();
          ctx.fillStyle = '#000000';
          ctx.fillText(`${Number(value).toFixed(0)}%`, x, y);
        });
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };

  const customLegend = {
    beforeInit(chart) {
      // Get a reference to the original fit function
      const originalFit = chart.legend.fit;
      // Override the fit function
      chart.legend.fit = function fit() {
        // Call the original function and bind scope in order to use `this` correctly inside it
        originalFit.bind(chart.legend)();
        // Change the height
        this.height += 10;
      }
    }
  }

  return (
    <div className="pl-0 w-hp-allowance-distribution h-[200px] lg:h-[380px] xl:h-[400px] 2xl:h-[437px]" id='users-allowances-distribution' >
      <div className="chart-container">
        <div className='border w-full h-full rounded-t h-[92px] bg-white'>
          <div className='flex flex-col w-full px-6 pt-6 pb-4 gap-2'>
            <p className='font-sans text-xl/[24px] font-semibold text-left text-black-report' >
              {i18n.t(`homePage.remunerations.allowances.title`)}
            </p>
            <p className='font-sans text-sm/[20px] font-normal text-left text-cyan-blue-medium-dark' >
              {i18n.t(`homePage.remunerations.allowances.subtitle`)}
            </p>
          </div>
          {/* <EvolutionMenu /> */}
        </div>
        <div
          className='border-l border-r border-b rounded-b mt-0 w-auto lg:h-[288px] xl:h-[298px] 2xl:h-[345px] bg-white'
          id='chart-allowances-distribution'
        >
          <Pie data={data} options={options} plugins={[customLegend]} />
        </div>
      </div>
    </div>
  )
}
