import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from "react-chartjs-2";
import i18n from "#translate/i18n";
import fetchReadjustmentsEvolution from "#requests/users/fetchReadjustmentsEvolution"
import LoadingPage from "#components/navigation/LoadingPage";

export default function ReadjustmentsDistribution() {
  const [readjustmentsData, setReadjustmentsData] = useState([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    fetchReadjustmentsEvolution()
      .then((response) => response.json())
      .then((parsedResponse) => {
        setReadjustmentsData(parsedResponse)
        setloading(false)
      });
  }, []);

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const barOptions = {
    locale: 'pt-BR',
    layout: {
      padding: {
        top: -20,
        bottom: 5, // Add padding at the bottom
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: '#72777B',
          padding: 8
        },
        grid: {
          drawTicks: false,
          color: '#E7EAEE'
        },
        title: {
          display: true,
          text: i18n.t('homePage.remunerations.readjustments.xAxis'),
          padding: {
            top: 20
          },
          color: '#485668',
          font: {
            size: 12,
            weight: 'normal'
          }
        },
        border: {
          color: '#E7EAEE'
        }
      },
      y: {
        stacked: true,
        grid: {
          drawTicks: false,
          color: '#E7EAEE'
        },
        title: {
          display: true,
          text: i18n.t('homePage.remunerations.readjustments.yAxis'),
          padding: {
            bottom: 20
          },
          color: '#485668',
          font: {
            size: 12,
            weight: 'normal'
          }
        },
        ticks: {
          beginAtZero: true,
          color: '#72777B',
          padding: 8
        },
        border: {
          color: '#E7EAEE'
        }
      },
      secondYAxis: {
        type: 'linear',
        position: 'right',
        ticks: { display: false },
        grid: { display: false },
        border: {
          color: '#E7EAEE'
        }
      },
    },
    plugins: {
      legend: {
        display: true,
        align: "start",
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          boxWidth: 10,
          boxHeight: 10,
          padding: 38.5,
          fullSize: true,
          color: '#2D2D2D',
        },
      },
      title: {
        display: false
      }
    }
  };
  const data = {
    labels: Object.keys(readjustmentsData).map((x) => i18n.t(`homePage.remunerations.month.${x}`)),
    datasets: [
      {
        label: i18n.t('homePage.remunerations.readjustments.legends.sum_readjustments'),
        data: Object.keys(readjustmentsData).map((x) => readjustmentsData[x].sum_readjustments),
        backgroundColor: '#00C7F2',
        barPercentage: 0.5,
      }
    ]
  };

  const customLegend = {
    beforeInit(chart) {
      // Get a reference to the original fit function
      const originalFit = chart.legend.fit;
      // Override the fit function
      chart.legend.fit = function fit() {
        // Call the original function and bind scope in order to use `this` correctly inside it
        originalFit.bind(chart.legend)();
        // Change the height
        this.height += 10;
      }
    }
  }

  return (
    <div className="h-full w-full lg:h-[380px] xl:h-[400px] 2xl:h-[437px]" id='users-readjustments-evolution' >
      <div className="chart-container">
        <div className='border w-full rounded-t h-[92px] px-6 pt-6 pb-4 bg-white'>
          <p className='font-sans text-xl/6 font-semibold text-left text-black-report mb-2' >
            {i18n.t(`homePage.remunerations.readjustments.title`)}
          </p>
          <p className='font-sans text-sm/5 font-normal text-left text-cyan-blue-medium-dark' >
            {i18n.t(`homePage.remunerations.readjustments.subtitle`)}
          </p>
          {/* <EvolutionMenu /> */}
        </div>
        <div
          className='border-l border-r border-b pl-5 pr-5 pb-5 rounded-b m-0 lg:h-[288px] xl:h-[298px] 2xl:h-[345px] bg-white'
          id='chart-readjustments-distribution'
        >
          {
            loading ? <LoadingPage customHeight="h-[200px]" customWidth="w-full" /> : (
              <Bar
                options={barOptions}
                data={data}
                plugins={[customLegend]}
              />
            )
          }
        </div>
      </div>
    </div>
  )
}
