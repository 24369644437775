import React, { useState, useEffect } from "react";
import i18n from "#translate/i18n";
import { Trans } from "react-i18next"
import quintalk from '#images/events_icons/quintalk.svg';
import live_coding from '#images/events_icons/live_code.svg';
import competitions from '#images/events_icons/competitions.svg';
import party_kit from '#images/events_icons/party_kit.svg';
import onboarding from '#images/events_icons/onboarding.svg';
import ArrowDown from '#images/arrowdown-top.svg';
import EditIconBlue from "#images/edit-blue.svg"
import EditIconGray from "#images/edit-gray.svg"
import DeleteIconRed from "#images/delete-red.svg"
import DeleteIconGray from "#images/delete-gray.svg"
import TooltipMessageHoverButtons from "#components/TooltipMessageHoverButtons";

export default function BonusRewardCreated({
  eventData,
  removedArray,
  lastUpdates,
  setOpenRemovalConfirmation,
  setOpenRemovalSuccess,
  setObjectId,
  setChosenEventRemoval,
  showDetails,
  setShowDetails,
  openEditionDrawer
}) {
  const todayStart = new Date()
  todayStart.setHours(0, 0, 0, 0);
  const data = eventData?.data || {}
  const paymentDate = data.payment_date
  const isRemoved = removedArray.some((removedItem) => removedItem.id === data.id && removedItem.creationEventName === eventData?.name)
  const wasUpdated = lastUpdates.some((update) => update.id === data.id)
  const isValid = new Date(`${paymentDate}T00:00`) >= todayStart
  const adminName = data.responsible?.full_name
  const bonusValue = Number(data.bonus_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  const [year, month, day] = paymentDate.split('-')
  const rewardType = data.reward_type
  const paymentType = data.payment_type
  const icons = {
    quintalk: quintalk,
    live_coding: live_coding,
    competitions: competitions,
    party_kit: party_kit,
    onboarding: onboarding,
  }
  const [click, setClick] = useState(false)

  useEffect(() => {
    if (showDetails === 'close') { setClick(false) }
  }, [showDetails]);

  return (
    <div className="relative flex-1 my-5 bg-white">
      <div className="flex items-center">
        {icons[rewardType] && (
          <div className='rounded-full shadow-timeline-icons mr-2 h-fit min-w-fit'>
            <img
              src={icons[rewardType]}
              alt="event icon"
              className='w-12 h-12 p-1'
            />
          </div>
        )}
        <div className="relative z-20 font-inter text-sm leading-[22px] text-info-gray flex-1">
          <p className="font-bold inline-block">
            {i18n.t(`timelineEvents.bonusRewardCreated.${rewardType}`)}
          </p>
          <p className="font-normal">
            <Trans
              i18nKey={`${i18n.t("timelineEvents.bonusRewardCreated.description")}`}
              values={{ admin: adminName, payment: bonusValue, day: day, month: month, year: year }}
            />
          </p>
        </div>
        <button
          id={`reward_created_details_button_${rewardType}`}
          className='ml-6 mb-3 p-2'
          onClick={() => { setClick(!click), setShowDetails(null), setOpenRemovalSuccess(false) }}
        >
          <img
            className={`w-3 h-[7.2px] right-0 ${click && 'transform rotate-180'} min-w-max`}
            src={ArrowDown}
            alt="menu arrow"
          />
        </button>
      </div>
      {click && (
        <>
          <div className="relative font-inter text-sm leading-6 text-info-gray mt-3 ml-14" id='reward-created-details-panel'>
            <p id={`bonus_reward_payment_type_${rewardType}`} >
              <span className="font-bold"> {i18n.t('timelineEvents.bonusRewardCreated.payment_type.title')} </span>
              {i18n.t(`timelineEvents.bonusRewardCreated.payment_type.${paymentType}`)}
            </p>
            <p className="font-bold"> {i18n.t('timelineEvents.bonusRewardCreated.observations')} </p>
            <p id={`bonus_reward_notes_${rewardType}`}>{data.notes || i18n.t(`timelineEvents.bonusRewardCreated.missing_notes`)} </p>
          </div>
          {isValid && !wasUpdated && (
            <div className="flex space-x-4 justify-end mt-5" id={`reward_created_details_buttons_${rewardType}`}>
              <div className="flex space-x-4 group">
                {isRemoved && (
                  <>
                    <TooltipMessageHoverButtons
                      buttonClass="absolute h-11 w-[370px] right-2"
                      tooltipClass="absolute bg-border-blue z-1 gap-1 text-white items-center text-center w-[490px] text-xs px-3 py-1 h-9 font-semibold
                                    rounded-lg bg-tooltip-color shadow-tooltip-buttons ml-[-40px]"
                      arrowClass='absolute ml-[-50px]'
                      messageClass="h-5 pt-1"
                      message={i18n.t('timelineEvents.removedMessage')}
                    />
                  </>
                )}
                <button
                  className={`justify-center font-sans font-semibold text-base bg-white flex items-center px-4 py-2.5
                              ${isRemoved ? 'text-disabled-gray' : 'text-support-error'}`}
                  id={`reward_remove_button_${rewardType}`}
                  disabled={isRemoved}
                  onClick={() => { setOpenRemovalConfirmation(true), setObjectId(data.id), setChosenEventRemoval(eventData), setShowDetails(null) }}
                >
                  <img src={isRemoved ? DeleteIconGray : DeleteIconRed} alt="" className="inline mr-2 w-6 h-6" />
                  {i18n.t('timelineEvents.bonusRewardCreated.removeReward')}
                </button>

                <button
                  className={`justify-center font-sans font-semibold text-base rounded border
                              flex items-center px-4 py-2.5 ${isRemoved ? 'text-disabled-gray bg-disabled-gray-button' : 'text-rebase-blue border-rebase-blue bg-white'}`}
                  id={`reward_edit_button_${rewardType}`}
                  disabled={isRemoved}
                  onClick={() => { openEditionDrawer(), setObjectId(data.id) }}
                >
                  <img src={isRemoved ? EditIconGray : EditIconBlue} alt="" className="inline mr-2 w-6 h-6 stroke-rebase-blue" />
                  {i18n.t('timelineEvents.bonusRewardCreated.editReward')}
                </button>
              </div>
              <div className="w-0 h-0 text-disabled-gray bg-disabled-gray-button text-disabled-gray" />
            </div>
          )}
        </>
      )}
    </div>
  );
}
