import React from "react";
import i18n from "#translate/i18n";

import Collapsible from "#components/listings/people/data/drawers/Collapsible"
import DivisionLine from "#components/formComponents/DivisionLine";
import CompanyDataIcon from "#images/company-info-icon.svg";

import BasicCompanyData from "./BasicCompanyData";
import CompanyAddressData from "./CompanyAddressData";
import FillOrCleanSelection from "./FillOrCleanSelection";

export default function CompanyData({ fieldsCompanyDataOptions, openSection, setOpenSection }) {
	return (
		<Collapsible
			openSection={openSection}
			setOpenSection={setOpenSection}
			section={'company_data'}
			buttonIcon={CompanyDataIcon}
			buttonText={i18n.t('customizedDataExport.selections.sections.company_data.title')}
			buttonId={'company_data_options'}
			collapseComponent={
				<div className="pb-4 gap-3 text-title-gray">
					<FillOrCleanSelection sectionName={'company_data'} sectionDataOptions={fieldsCompanyDataOptions} />
					<BasicCompanyData fieldsCompanyDataOptions={fieldsCompanyDataOptions} />
					<DivisionLine customStyle='mb-6' />
					<CompanyAddressData fieldsCompanyDataOptions={fieldsCompanyDataOptions} />
				</div>
			}
		/>
	);
}
