import React from "react";
import i18n from "#translate/i18n";

import Collapsible from "#components/listings/people/data/drawers/Collapsible"
import thumb_up from "#images/white_thumb_up.svg";
import CustomizedFieldsOptions from "#components/listings/people/data/drawers/CustomizedFieldsOptions"
import FillOrCleanSelection from "./FillOrCleanSelection";

export default function PersonalPreferencesData({ fieldsPersonalPreferencesDataOptions, openSection, setOpenSection }) {
	return (
		<Collapsible
			openSection={openSection}
			setOpenSection={setOpenSection}
			section='personal_preferences_data'
			buttonIcon={thumb_up}
			buttonText={i18n.t('customizedDataExport.selections.sections.personal_preferences_data.title')}
			buttonId={'personal_preferences_data_options'}
			collapseComponent={
				<div className="pb-4 gap-3 text-title-gray">
					<FillOrCleanSelection sectionName={'personal_preferences_data'} sectionDataOptions={fieldsPersonalPreferencesDataOptions} />
					<CustomizedFieldsOptions
						options={fieldsPersonalPreferencesDataOptions}
						prefix={'personal_preferences_data'}
					/>
				</div>
			}
		/>
	);
}
