import React from "react";
import i18n from "#translate/i18n";

import Collapsible from "#components/listings/people/data/drawers/Collapsible"
import apartmentIcon from "#images/apartment_icon.svg";
import DivisionLine from '#components/formComponents/DivisionLine';
import BasicCompanyHistoryData from "#components/listings/people/data/drawers/BasicCompanyHistoryData";
import AssetControlData from "#components/listings/people/data/drawers/AssetControlData";
import FillOrCleanSelection from "./FillOrCleanSelection";
import ProjectHistoryData from "#components/listings/people/data/drawers/ProjectHistoryData";

export default function CompanyHistoryData({ fieldsCompanyHistoryDataOptions, openSection, setOpenSection }) {
	return (
		<Collapsible
			openSection={openSection}
			setOpenSection={setOpenSection}
			section={'company_history_data'}
			buttonIcon={apartmentIcon}
			buttonText={i18n.t('customizedDataExport.selections.sections.company_history_data.title')}
			buttonId={'company_history_data_options'}
			collapseComponent={
				<div className="pb-4 gap-3 text-title-gray">
					<FillOrCleanSelection sectionName={'company_history_data'} sectionDataOptions={fieldsCompanyHistoryDataOptions} />
					<BasicCompanyHistoryData fieldsCompanyHistoryDataOptions={fieldsCompanyHistoryDataOptions} />
					<DivisionLine customStyle='mb-6' />
					<AssetControlData fieldsCompanyHistoryDataOptions={fieldsCompanyHistoryDataOptions} />
					<DivisionLine customStyle='mb-6' />
					<ProjectHistoryData fieldsCompanyHistoryDataOptions={fieldsCompanyHistoryDataOptions} />
				</div>
			}
		/>
	);
}
