import React from "react";
import i18n from "#translate/i18n";

import Collapsible from "#components/listings/people/data/drawers/Collapsible"
import BankIcon from "#images/bank-icon.svg";
import CustomizedFieldsOptions from "#components/listings/people/data/drawers/CustomizedFieldsOptions"
import FillOrCleanSelection from "./FillOrCleanSelection";

export default function BankAccountData({ fieldsBankAccountDataOptions, openSection, setOpenSection }) {
	return (
		<Collapsible
			openSection={openSection}
			setOpenSection={setOpenSection}
			section='bank_account_data'
			buttonIcon={BankIcon}
			buttonText={i18n.t('customizedDataExport.selections.sections.bank_account_data.title')}
			buttonId={'bank_account_data_options'}
			collapseComponent={
				<div className="pb-4 gap-3 text-title-gray">
					<FillOrCleanSelection sectionName={'bank_account_data'} sectionDataOptions={fieldsBankAccountDataOptions} />
					<CustomizedFieldsOptions
						options={fieldsBankAccountDataOptions}
						prefix={'bank_account_data'}
					/>
				</div>
			}
		/>
	);
}
