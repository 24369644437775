import React, { useState, Fragment, useEffect, useRef } from "react";
import { Combobox } from "@headlessui/react";
import { useFormContext, get, Controller } from "react-hook-form";
import ArrowDown from "#images/arrowdown-top.svg";
import ClearButton from "#images/close-button.svg";
import i18n from "#translate/i18n";

const popularBanks = [
  '237 - BANCO BRADESCO S.A.',
  '218 - BANCO BS2 S.A.',
  '336 - BANCO C6 S.A.',
  '001 - BANCO DO BRASIL S.A.',
  '077 - BANCO INTER S.A.',
  '341 - BANCO ITAÚ UNIBANCO S.A.',
  '033 - BANCO SANTANDER (BRASIL) S.A.',
  '655 - BANCO VOTORANTIM S.A.',
  '348 - BANCO XP S.A.',
  '104 - CAIXA ECONÔMICA FEDERAL',
  '403 - CORA SOCIEDADE DE CRÉDITO DIREITO',
  '260 - NU PAGAMENTOS S.A.',
  '323 - MERCADO PAGO REPRESENTAÇÕES LTDA.',
];
const header = ['Bancos mais procurados', 'Outros bancos'];

export default function BankSelectInput({
  bankingInstitutions,
  model,
  title,
  requiredField,
  errorProps,
  name: inputName,
  index,
  ...props
}) {

  const {
    formState: { errors },
    control,
    getValues,
    setValue,
  } = useFormContext();

  const [selectedBank, setSelectedBank] = useState(null);
  const [query, setQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [click, setClick] = useState(false)
  const buttonRef = useRef()
  const inputRef = useRef()

  const name = model ? `${model}.${inputName}` : inputName;
  const initialFieldValue = getValues(name);
  const fieldError = get(errors, name);
  const invalidClassName = fieldError ? "ring-2 ring-red-500" : "";
  const className = `${props.className || ''} ${invalidClassName}`;

  useEffect(() => {
    if (initialFieldValue) {
      const initialValue = bankingInstitutions.find((bank) => bank.id === initialFieldValue);
      setSelectedBank(initialValue?.bank_institution);
    }
  }, [initialFieldValue]);

  const filteredBanks =
    query === ''
      ? bankingInstitutions
      : bankingInstitutions.filter((bank) =>
        bank.bank_institution.toUpperCase().includes(query.toUpperCase())
      );

  const groupByHeader = (banks) => {
    const grouped = {
      [header[0]]: [],
      [header[1]]: [],
    };

    banks.forEach((bank) => {
      popularBanks.includes(bank.bank_institution) ? grouped[header[0]].push(bank) : grouped[header[1]].push(bank);
    });

    return grouped;
  };

  const handleComboboxChange = (value, field) => {
    setSelectedBank(value ? value.bank_institution : null);
    field.onChange(value ? value.id : null);
    if(!value) {
      setQuery('')
    }
  };

  const handleComboboxInputClick = () => {
    !selectedBank && buttonRef.current.click()
    setIsOpen(true);
  };

  const handleClearButtonClick = (e) => {
    e.preventDefault();
    clearQuery();
  };

  const handleComboboxButtonClick = () => {
    setClick(!click)
    setIsOpen(!isOpen)
  };

  const clearQuery = () => {
    setQuery('');
    setSelectedBank(null);
    setValue(name, '');
  };

  const groupedBanks = groupByHeader(filteredBanks);

  const ErrorComponent = ({ message, errorId }) => {
    return (
      <span
        {...errorProps}
        className="items-center font-medium tracking-wide text-red-500 text-xs mt-[-6px]"
        role="alert"
        name="validationError"
        id={`${errorId}_error`}
      >
        {message}
      </span>
    );
  };

  ErrorComponent.displayName = "ErrorComponent";

  return (
    <>
      <p className="text-title-gray text-sm font-medium mb-[-3px]">
        {title}
        {requiredField && (
          <span className="pl-1 text-red-500" id="required-field">
            *
          </span>
        )}
      </p>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Combobox
            value={selectedBank}
            onChange={(value) => handleComboboxChange(value, field)}
            nullable
          >
            <div className="relative">
              <Combobox.Input
                ref={inputRef}
                id={`${model}_${index}_bank_id_input`}
                className={`${className} w-full py-2 pl-4 pr-12 border border-gray-300 rounded-md focus:outline-none focus:border-border-blue`}
                placeholder="Busque pelo seu banco"
                onChange={(event) => setQuery(event.target.value)}
                onClick={handleComboboxInputClick}
                onFocus={() => setIsOpen(true)}
                displayValue={selectedBank ? selectedBank?.bank_institution : query}
              />
              {(selectedBank || query) && (
                <button
                  id={`clear-button-${index}`}
                  onClick={handleClearButtonClick}
                  className="absolute right-4 top-1.5 px-4 py-2 rounded-md cursor-pointer"
                >
                  <img src={ClearButton} alt="Clear button" />
                </button>
              )}
              <Combobox.Button
                ref={buttonRef}
                onClick={handleComboboxButtonClick}
                className="absolute right-0 top-3 pl-2 pr-4 py-2 rounded-md"
              >
                <img
                  src={ArrowDown}
                  className={`w-2 h-[5px] ${click && 'transform rotate-180'}`}
                  alt="Toggle Options"
                />
              </Combobox.Button>
            </div>
            <Combobox.Options className={`bg-white border border-border-blue rounded-md ${isOpen ? '' : 'hidden'}`}>
              {Object.values(groupedBanks).every((banks) => banks.length === 0) ? (
                <div className="relative cursor-default select-none px-4 py-2 text-cyan-blue-medium-dark">
                  {i18n.t("bank_account.not_found")}
                </div>
              ) : (
                <>
                  {Object.entries(groupedBanks).map(([group, banks]) => (
                    banks.length > 0 && (
                      <Fragment key={group}>
                        <div className={`p-4 font-bold text-cyan-blue-medium-dark text-base leading-7 ${group === header[0] ? 'border-b' : 'border-y'} border-disabled-gray-button`}>
                          {group}
                        </div>
                        {banks.map((bank) => (
                          <Combobox.Option
                            className={({ active }) => `text-cyan-blue-medium-dark p-4 cursor-pointer hover:bg-light-gray text-sm hover:font-semibold ${active ? 'bg-light-gray text-cyan-blue-medium-dark p-4 text-sm font-semibold' : ''} `}
                            key={bank.id}
                            value={bank}
                          >
                            {bank.bank_institution}
                          </Combobox.Option>
                        ))}
                      </Fragment>
                    )
                  ))}
                </>
              )}
            </Combobox.Options>
          </Combobox>
        )}
      />
      {fieldError && <ErrorComponent message={fieldError.message} errorId={`${model}_${index}_bank_id_input`}/>}
    </>
  );
}
