import * as Yup from "yup";
import i18n from "#translate/i18n";
import { YupBasicFileValidation, YupFileValidation, YupFile } from "#utils/yup/types/file";
import { isValidCPF } from '#utils/schemas';

Yup.setLocale(i18n.t("yup.schemas", { returnObjects: true }));

const SOCIAL_NAME_REGEX = /^[a-zA-ZáàâãéèêíïóôõöúçÁÀÂÃÉÈÍÏÓÔÕÖÚÇ'\s]*$/;

const { errors: errorLabel } = i18n.t("yup.forms", { returnObjects: true });

const userBaseSchema = Yup.object().shape({
  full_name: Yup.string()
    .label()
    .transform((name) => name.trim())
    .required(),
  birth_date: Yup.string().required(),
  hiring_date: Yup.string().required(),
  pronouns: Yup.string().required(),
  marital_status: Yup.string().required(),
  gender_identity: Yup.string().required(),
  lgbtqia_plus: Yup.string().required(),
  rg: Yup.string().required(),
  cpf: Yup.string()
          .required()
          .min(13, errorLabel.cpf_invalid)
          .test('CPF validation', errorLabel.cpf_invalid, isValidCPF),
  has_social_name: Yup.string().required(),
  social_name: Yup.string()
    .matches(SOCIAL_NAME_REGEX, errorLabel.social_name)
    .when("has_social_name", {
      is: 'true',
      then: (schema) => schema.required(),
    }),
  gender_description: Yup.string().when("gender_identity", {
    is: 'describe_myself',
    then: (schema) => schema.required(),
  }),

  disability_notes: Yup.string().when("disability", {
    is: 'has_disability',
    then: (schema) => schema.required(),
  }),
  general_notes: Yup.string().when("health_notes", {
    is: 'true',
    then: (schema) => schema.required(),
  }),
  allergies: Yup.string(),
  sharing_home_with: Yup.string().required(),
  race_color: Yup.string().required(),
  disability: Yup.string().required(),
  human_children: Yup.string().required(),
  rg_front: YupBasicFileValidation({
    defaultSchema: YupFile().oneFile().filePresence().fileSize().fileType()
  }),
  rg_back: YupBasicFileValidation({
    defaultSchema: YupFile().oneFile().filePresence().fileSize().fileType()
  }),
  cpf_front: YupBasicFileValidation({
    defaultSchema: YupFile().oneFile().filePresence().fileSize().fileType()
  }),
  cpf_back: YupBasicFileValidation({
    defaultSchema: YupFile().oneFile().filePresence().fileSize().fileType()
  }),
  residence_proof: YupBasicFileValidation({
    defaultSchema: YupFile().oneFile().filePresence().fileSize().fileType()
  }),
  cnpj_card: YupFileValidation(),
});

export default userBaseSchema;
