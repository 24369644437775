import React, { useEffect } from "react";
import { useWatch, useFormContext } from "react-hook-form";
import i18n from "#translate/i18n";
import DivisionTitle from "../../DivisionTitle";
import SelectInput from "../../SelectInput";
import DescendantsFields from "./DescendantsFields";
import TextAreaInput from "#components/formComponents/TextAreaInput";

const MAX_MEMBERS = 15;

export default function SocioeconomicFields() {
  const { setValue } = useFormContext();
  const model = "user";

  const human_children = useWatch({ name: `${model}.human_children` });
  const disability = useWatch({ name: `${model}.disability` });

  const hasDisability = String(disability) === "has_disability" ? true : false
  const showChildrenList = String(human_children) === "true" ? true : false

  const manageFields = () => {
    setValue(`${model}.disability_notes`, "")
  }

  useEffect(() => {
    if (!hasDisability) {
      manageFields()
    }
  }, [hasDisability]);

  const numbers = [...Array(MAX_MEMBERS).keys()].map((number) => ++number);
  const races = [
    "black",
    "white",
    "yellow",
    "brown",
    "indigenous",
    "without_answer",
  ];
  const disabilities = [
    "has_disability",
    "no_disability",
  ];

  return (
    <div>
      <DivisionTitle title={i18n.t("socioeconomic_data")} />

      <div className="grid grid-cols-2 gap-y-3 gap-x-9">
        <SelectInput
          model={model}
          name="sharing_home_with"
          options={numbers}
          title={i18n.t("sharing_home_with.sharing_home_with")}
          subtitle={i18n.t("sharing_home_with.subtitle")}
          requiredField={true}
          className='w-full'
        />
        <SelectInput
          model={model}
          name="race_color"
          options={races}
          title={i18n.t("race_color.race_color")}
          requiredField={true}
          className='w-full'
        />
      </div>
      <div className="grid gap-y-3 gap-x-9 mt-2.5">
        <SelectInput
          model={model}
          name="disability"
          options={disabilities}
          title={i18n.t("disability.disability")}
          requiredField={true}
          className='w-full'
          // subtitle={
          //   <>
          //     { `${i18n.t("disability.text")}, ` }
          //     <a
          //       href="https://idt.org.br/pcd/tipos-de-deficiencia#myNavbar"
          //       target="_blank"
          //       rel="noreferrer"
          //       className="text-rebase-blue underline underline-offset-2 font-medium cursor-pointer text-sm"
          //       id="disability-link"
          //       >
          //         {`${i18n.t("disability.link")}`}
          //       </a>
          //   </>
          // }
        />
        {hasDisability && (
          <TextAreaInput
            model={model}
            title={i18n.t("disability_notes")}
            name="disability_notes"
            requiredField={ true }
            placeholder={i18n.t("placeholders.disability_notes")}
            className='w-full'
          />
        )}
        
        <SelectInput
          model={model}
          name="human_children"
          options={["true", "false"]}
          title={i18n.t("human_children.human_children")}
          requiredField={true}
          className='w-full'
        />
      </div>
      <div className={showChildrenList ? '' : 'hidden'} id='descendants-fields'>
        <div className='h-[15px]' />
        <DescendantsFields hasChildren={showChildrenList} />
      </div>
    </div>
  );
}
