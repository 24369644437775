import React from "react";
import CloseButton from "#images/buttons/button_error_red.svg";
import i18n from "#translate/i18n";
import { Trans } from "react-i18next"

export default function ErrorMessage({ errors, removeErrors, model, userName, className }) {
  function handleCloseButton() {
    removeErrors(null)
  }

  return (
    <div className={`bg-support-error-background rounded px-4 py-2 relative my-3 ${className}`} role="alert">
      <span className="absolute top-0 bottom-0 left-0 px-4 py-3">
        <button
          id="error_message_close_button"
          type="button"
          className="justify-center flex-none font-sans Helvetica Neue font-medium text-base
            rounded-md flex items-center w-5 h-full"
          onClick={() => handleCloseButton(false)}
        >
          <img
            id='close_error_message'
            src={CloseButton}
            alt={i18n.t(`error.messageForm.closeError`)}
            className="relative zIndex-30"
          />
        </button>
      </span>
      {Object.keys(errors)?.map((errorKey) => (
        <div key={`${errorKey}`}>
          {errors[errorKey].map((errorValue, valueIndex) => (
            <p className="text-dark-gray-scale ml-7" key={`${errorKey}_${valueIndex}`}>
              <span className="block sm:inline text-sm font-sans font-bold text-dark-gray-scale">
                {`${userName} `}
              </span>
              <span className="block sm:inline text-sm font-normal text-dark-gray-scale">
                <Trans
                  i18nKey={`${i18n.t(`${model}.messageForm.error.${errorKey}.${errorValue.replaceAll(' ', '_')}`)}`}
                />
              </span>
            </p>
          ))}
        </div>
      ))}
    </div>
  );
}
