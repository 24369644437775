import React, { useState, useEffect } from "react";
import InputText from "#components/formComponents/InputText";
import SelectInput from "#components/formComponents/SelectInput";
import CurrencyInput from "#components/formComponents/CurrencyInput";
import fetchCompanies from "#requests/fetchCompanies";
import i18n from "#translate/i18n";
import DateCustomInput from "#components/formComponents/DateCustomInput";
import InputMask from "react-input-mask";

export default function FormInviteUser() {
  const fontStyle = "text-cyan-blue-medium-dark text-xs font-sans leading-5 font-bold";

  const inviteFieldStyle = "w-full rounded-md border border-border-gray bg-white flex p-2.5 items-start gap-1 self-stretch placeholder-placeholder-gray";

  const dateInviteStyle = "w-full h-[46px] flex items-center rounded-md border border-border-gray bg-white"

  const [companies, setCompanies] = useState(['']);

  useEffect(() => {
    fetchCompanies()
      .then((parsedResponse) => {
        setCompanies(parsedResponse["companies"]);
    });
  }, []);

  return (
    <div className="mx-0.5">
      <div className="grid grid-cols-1 gap-y-3 gap-x-4 mb-2.5">
        <InputText
          data-testid="invite-full-name-input"
          inviteFieldStyle={inviteFieldStyle}
          title={<span className={fontStyle}>{i18n.t("invite.title")}</span>}
          name="full_name"
          requiredField={ true }
          model="user"
          placeholder="Sem abreviações"
        />
      </div>

      <div className="grid grid-cols-2 gap-y-3 gap-x-4">
        <div>
          <DateCustomInput
            dataTestId="invite-birth-date-input"
            inviteFieldStyle={inviteFieldStyle}
            model="user"
            name="birth_date"
            requiredField={ true }
            title={<span className={fontStyle}>{i18n.t("birth_date")}</span>}
            dateInviteStyle={dateInviteStyle}
            preventOpenOnFocus={true}
            customInput={<InputMask mask="99/99/9999"/>}
          />
        </div>
        <div>
          <DateCustomInput
            dataTestId="invite-hiring-date-input"
            inviteFieldStyle={inviteFieldStyle}
            model="user"
            name="hiring_date"
            title={<span className={fontStyle}>{i18n.t("hiring_date")}</span>}
            requiredField={ true }
            dateInviteStyle={dateInviteStyle}
            preventOpenOnFocus={true}
            customInput={<InputMask mask="99/99/9999"/>}
          />
        </div>
        <SelectInput
          data-testid="invite-company-input"
          inviteFieldStyle={inviteFieldStyle}
          model="company_history"
          name="company"
          requiredField={ true }
          options={companies}
          title={<span className={fontStyle}>{i18n.t("company_history.company_invite")}</span>}
        />

        <CurrencyInput
          data-testid="invite-initial-remuneration-input"
          inviteFieldStyle={inviteFieldStyle}
          model="company_history"
          name="initial_remuneration"
          title={<span className={fontStyle}>{i18n.t("company_history.initial_remuneration")}</span>}
          requiredField={ true }
          placeHolder={i18n.t("invite.placeHolder.currency")}
        />

        <InputText
          data-testid="invite-personal-email-input"
          inviteFieldStyle={inviteFieldStyle}
          model="contacts"
          title={<span className={fontStyle}>{i18n.t("personal_email")}</span>}
          name="0.personal_email"
          requiredField={ true }
        />

        <InputText
          data-testid="invite-corporate-email-input"
          inviteFieldStyle={inviteFieldStyle}
          model="contacts"
          title={<span className={fontStyle}>{i18n.t("corporate_email")}</span>}
          name="0.corporate_email"
          requiredField={ true }
        />
      </div>
    </div>
  );
};
