import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import i18n from "#translate/i18n";
import arrow from "#images/arrow-top-menu.svg"

import { UserFormsContext } from "#providers/UserFormsProvider"

function BreadCrumbs(props) {
  let urlElements = window.location.pathname;
  const { closeForms } = useContext(UserFormsContext)
  return (
    <>
      <div id='reports_navlink' className="pt-8 pb-5 mt-0 text-sm font-medium flex items-center">
        <NavLink className="text-cyan-blue-medium-dark" to={"/"} onClick={closeForms}>
          {i18n.t("home")}
        </NavLink>
        <img className="px-1" src={arrow} alt="arrow" />
        {(urlElements == "/pessoas" ||
          urlElements == "/" ||
          urlElements == "/reports") ? (
          <div className="text-rebase-blue">{props.name}</div>
        ) : (
          <div className="flex items-center">
            <NavLink className="text-cyan-blue-medium-dark" to={"/pessoas"} onClick={closeForms} >
              {i18n.t("users.title")}
            </NavLink>
            <img className="px-1" src={arrow} alt="arrow" />
            <p className="px-1 text-rebase-blue"> {` ${props.name}`}</p>
          </div>
        )}
      </div>
    </>
  );
}

export default BreadCrumbs;
