import React from "react";
import i18n from "#translate/i18n";
import removalIcon from '#images/profile_drawer/removal_button.svg';
import CloseIcon from '#images/close-btn.svg';
import userPresentedName from '#services/userPresentedName';

export default function RemovalConfirmation({ removalFunction, cancelFunction, userData, i18nTitle, i18nText }) {
  return (
    <div className='bg-black bg-opacity-30 fixed top-0 left-0 right-0 bottom-0 z-[1400] h-screen' >
      <div className='h-full w-full flex justify-center items-center' >
        <div className="bg-white z-[2000] w-[700px] h-60 p-6 absolute
                        shadow-[0px_8px_10px_0px_rgba(0,0,0,0.20),0px_6px_30px_0px_rgba(0,0,0,0.12),0px_16px_24px_0px_rgba(0,0,0,0.14)]"
        >
          <div className="flex flex-col items-center bg-white w-[640px]" >
            <button
              id='close-removal-confirmation'
              className='flex p-2.5 justify-center items-center absolute right-3 top-6'
              onClick={() => cancelFunction()}
            >
              <img
                className={`w-3 h-3 min-w-max`}
                src={CloseIcon}
                alt="close button"
              />
            </button>
            <div className='flex p-[8px_0px_0px_0px] items-start gap-4 self-stretch' >
              <div className='flex w-12 h-12 justify-center items-center rounded-[28px] border-8 border-solid
                              border-[var(--error-50,#FEF3F2)] bg-[var(--error-100,#FEE4E2)]'
              >
                <img
                  src={removalIcon}
                  alt="event icon"
                />
              </div>
              <div className='flex flex-col justify-center items-start gap-1 flex-1' >
                <p className='text-black font-sans text-xl not-italic font-semibold' >
                  {i18n.t(`${i18nTitle}`)}
                </p>
                <p className='text-gray-text font-normal font-sans text-sm not-italic' >
                  {i18n.t(`${i18nText}`)}
                  <span className='text-cyan-blue-medium-dark font-sans text-sm not-italic font-bold' >
                    {userPresentedName(userData)}
                  </span>{`. ${i18n.t('removalConfirmation.text')}`}
                </p>
              </div>
            </div>
          </div>
          <div className='p-[0px_24px_24px_24px] flex justify-end items-center gap-6 self-stretch absolute bottom-2 right-2' >
            <button
              className="justify-center font-sans font-semibold text-base bg-white
                        text-rebase-blue rounded flex items-center px-4 py-2.5"
              id="removal-give-up-button"
              onClick={() => cancelFunction()}
            >
              {i18n.t('removalConfirmation.quit')}
            </button>

            <button
              className="justify-center items-center gap-2.5 py-2.5 px-4 flex rounded font-sans font-semibold text-base
                         bg-support-error text-white border w-[112px] h-11"
              id="removal-confirmation-button"
              onClick={() => removalFunction()}
            >
              {i18n.t('removalConfirmation.confirm')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
