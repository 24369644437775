const translations = {
  salary_readjustment_create: {
    title: "Reajuste de remuneração",
    subtitle: "Adicione uma nova remuneração para",
    submit_button_text: "Reajustar remuneração",
  },
  salary_readjustment_update: {
    title: "Editar remuneração",
    subtitle: "Edite a remuneração para",
    submit_button_text: "Salvar edição",
  },
  salary_readjustment: {
    current_value: "Remuneração atual",
    new_value: "Valor da nova remuneração",
    percentage: "Percentual de reajuste",
    valid_from: "Data base",
    valid_from_details: "(Novo valor entra em vigor em):",
    readjustment: "Valor do reajuste",
    notes: "Observações",
    new_value_placeholder: "4250",
    percentage_placeholder: "30.76%",
    notes_placeholder: "Se houver, adicione informações relevantes sobre este pedido",
    create_button_text: "Reajustar remuneração",
    update_button_text: "Atualizar remuneração",
    forms_error: {
      presence: "O preenchimento deste campo é obrigatório",
      value_added: "A nova remuneração não deve ser inferior à atual",
      date_error: "A data de início não pode ser igual ou inferior à data do último reajuste",
      first_time_date_error: "A data base não pode ser inferior a data de hoje"
    },
    messageForm: {
      success: "Reajuste de remuneração realizado",
      successDataCreated_1: "A remuneração de",
      successDataCreated_2: "foi reajustada com sucesso",
      error: {
        daily_limit: {
          'já_foi_cadastrado_um_reajuste_salarial_para_este_usuário_hoje': 'já possui um reajuste salarial cadastrado hoje.',
        },
        valid_from_limit: {
          'já_foi_cadastrado_um_reajuste_salarial_para_este_usuário_com_a_mesma_validade,_remova_o_anterior_para_adicionar_um_novo_para_essa_validade': 'Um reajuste já foi cadastrado com esta mesma data base. Se deseja fazer alterações, remova ou edite o reajuste anterior',
        },
        new_value: {
          'deve_ser_menor_do_que_o_novo_salário_de_validade_posterior_ao_atual': 'já possui outro reajuste agendado com validade posterior à informada, porém com o valor menor do que o requerido.',
          'deve_ser_maior_do_que_o_salário_atual_ou_do_último_reajuste_com_validade_anterior_ao_atual': 'já possui outro reajuste agendado com validade anterior à informada, porém com o valor maior do que o requerido.',
        }
      },
    },
  },
}

export { translations }