import React from "react";
import InputMask from "react-input-mask";
import Field from "./Field";

const InputMaskedText = ({
  model,
  title,
  name,
  requiredField,
  mask,
  maskChar,
  placeholder,
  disabled,
  className,
  onBlur,
  ...props
}) => {
  const id = model ? `${model}_${name}_input` : `${name}_input`;

  return (
    <div id={`${model}_${name}`}>
      <Field
        {...props}
        component={InputMask}
        model={model}
        label={
          <>
            {title}

            { requiredField && (
              <span 
                className="pl-1 text-red-500"
                id="required-field"
              >
                *
              </span>
            )}
          </>
        }
        id={id}
        name={name}
        mask={mask}
        maskChar={maskChar}
        placeholder={placeholder || mask}
        onBlur={onBlur}
        disabled={disabled}
        className={`py-2 px-3 mt-2 text-md block rounded h-11 w-[100%]
                    bg-white border border-border-gray placeholder-placeholder-gray
                    focus:placeholder-gray-400 focus:bg-white focus:border-gray-600
                    focus:outline-none focus:ring-2 disabled:bg-border-gray disabled:text-placeholder-gray
                    ${className}`}
      />
    </div>
  );
};

InputMaskedText.defaultProps = {
  maskChar: null,
  placeholder: null,
  disabled: false,
  className: "",
};

export default InputMaskedText;