/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import i18n from "#translate/i18n"
import moment from "moment"

import RoundPicture from "#components/user/RoundPicture";

import NoAvatarIcon from "#images/no-avatar.svg"

export default function UserAbsence({ absence, row, tdClasses }) {
  return (
    <tr id={`row_${row}`} className="h-16">
      <td className={`${tdClasses} w-2/6`}>
        <div className="flex items-center h-full w-full text-left">
          <div id={`avatar_${row}`} className="flex items-center justify-center bg-white rounded-full drop-shadow-md w-12 h-12 mr-3">
          <RoundPicture divStyle="w-12 h-12 mx-auto mt-0.5" imgStyle="object-cover" src={absence.face_picture?.url || NoAvatarIcon} />
          </div>
          <span className="font-sans font-semibold text-sm text-cyan-blue-medium-dark mt-[3px]">
            {absence.full_name}
          </span>
        </div>
      </td>
      <td className={`${tdClasses} w-1/6`}>
        <div id={`client_${row}`}>
          <img src={absence.client_logo?.url} alt="" className="max-h-14 mt-2 max-w-full" />
        </div>
      </td>
      <td className={`${tdClasses} w-1/6`}>
        <span className="font-sans font-normal text-sm text-cyan-blue-medium-dark">
          {i18n.t(`homePage.absenceListing.absenceReason.${absence.reason}`)}
        </span>
      </td>
      <td className={`${tdClasses} w-1/6 pr-3 pl-4`}>
        <span className="font-sans font-normal text-sm text-cyan-blue-medium-dark">
          {moment(absence.start_date).format("DD/MM/YYYY")}
        </span>
      </td>
      <td className={`${tdClasses} w-1/6 pr-3 pl-4`}>
        <span className="font-sans font-normal text-sm text-cyan-blue-medium-dark">
          {moment(absence.return_date).format("DD/MM/YYYY")}
        </span>
      </td>
    </tr>
  );
}
