import React from "react";
import i18n from "#translate/i18n";
import magnifier from "#images/Lupa.svg";

export default function SearchOptions({ setSearchFieldString }) {
  return (
    <div role="search" className='font-sans relative mb-6'>
      <label
        htmlFor="data_options_search_input"
        className="font font-medium font-Inter leading-5 text-sm text-gray-disabled-dark-gray"
      >
        {i18n.t('customizedDataExport.selections.sections.search.title')}
      </label>

      <div className='flex py-2.5 px-3.5 space-x-2 rounded border border-border-gray focus-within:ring-2 ring-outline-blue'>
        <img src={magnifier} alt={i18n.t("searchInput.imgMagnifier")} className="customized-data-search-icon" />
        <input
          id="data_options_search_input"
          type="search"
          placeholder={i18n.t('customizedDataExport.selections.sections.search.placeholder')}
          className="w-full p-0 border-0 placeholder:text-placeholder-gray focus:ring-0"
          onChange={(event) => setSearchFieldString(event.target.value)}
        />
      </div>
    </div>
  );
}
