/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import i18n from "#translate/i18n";
import { useFormContext, useWatch, useFieldArray } from "react-hook-form";
import DateCustomInput from "#components/formComponents/DateCustomInput";
import CurrencyInput from '#components/formComponents/CurrencyInput';
import HiddenInput from "#components/formComponents/HiddenInput";
import UserDismissalPaymentMethodInput from "./UserDismissalPaymentMethodInput";
import isDateBygone from "#services/isDateBygone"

export default function UserDismissalInstallments({
  formModel,
  afterSavingButton,
  triggerValidation,
  editObjectId,
  editDismissalData,
}) {
  const model = 'invoice_installments_attributes'

  const { control, setValue, getValues } = useFormContext();
  const { fields } = useFieldArray({ control, name: model, });

  const installmentsNumber = useWatch({ name: `${formModel}.installments_number` });
  const totalInvoice = useWatch({ name: `${formModel}.invoice_total_value` });
  const payments = useWatch({ name: model });
  const [paidInstallments, setPaidInstallments] = useState(0);
  const [loading, setLoading] = useState(true);

  const addingFields = ({ limit, list }) => {
    for (let i = 0; i < limit; i++) {
      list.push({
        payment_date: '',
        installment_value: '',
        past_installment: 'false',
        installments_number: installmentsNumber
      })
    }
  }

  const reducingAndUpdatingFields = ({ limit, list }) => {
    for (let i = 0; i < limit; i++) {
      list.pop()
    }
  }

  const afterPaymentDate = (currentPaymentDate) => isDateBygone(currentPaymentDate)

  useEffect(() => {
    if (installmentsNumber && typeof +installmentsNumber === 'number') {
      let installmentsLines = []
      if (payments.length <= 1 && !payments[0]['installment_value'] && !payments[0]['payment_date']) {
        addingFields({ limit: installmentsNumber, list: installmentsLines })
      } else {
        installmentsLines = [...payments]
        let installmentNumberDifference = installmentsNumber - installmentsLines.length
        installmentNumberDifference >= 1 ? addingFields({ limit: installmentNumberDifference, list: installmentsLines }) :
          reducingAndUpdatingFields({ limit: -installmentNumberDifference, list: installmentsLines })
      }

      installmentsLines.forEach(obj => { obj['installments_number'] = installmentsNumber; });
      setValue(model, installmentsLines)
    }
    if (afterSavingButton) { triggerValidation() }
  }, [installmentsNumber]);

  useEffect(() => {
    const totalPayment = payments.reduce((accumulator, currentValue) => {
      const paymentValue = currentValue.installment_value ? Number(currentValue.installment_value) : 0
      return accumulator + paymentValue
    }, 0);
    setValue(`${formModel}.installments_sum`, totalPayment)
  }, [totalInvoice, payments]);

  useEffect(() => {
    if (editObjectId && editDismissalData) {
      const installmentsData = [...editDismissalData.invoice_installments]
      const currentPaidInstallments = []
      installmentsData.map((data, index) => {
        setValue(`${model}.${index}.installment_value`, data.installment_value)
        setValue(`${model}.${index}.payment_date`, data.payment_date)
        setValue(`${model}.${index}.installment_id`, data.id)
        const isPastInstallment = afterPaymentDate(data.payment_date)
        if (isPastInstallment) { currentPaidInstallments.push(data) }
        setValue(`${model}.${index}.past_installment`, isPastInstallment)
      })
      setPaidInstallments(currentPaidInstallments.length)
      setLoading(false)
    } else if(!editObjectId) {
      setLoading(false)
    }
  }, [editDismissalData]);

  return (
    <>
      <div className="grid grid-cols-2 gap-y-3 gap-x-4 mt-2.5 mb-2.5">
        <CurrencyInput
          model={formModel}
          name={'invoice_total_value'}
          title={i18n.t(`${formModel}.invoice_total_value`)}
          placeHolder={"Ex: R$ 5.000,00"}
          className="w-full"
          requiredField={true}
        />

        <UserDismissalPaymentMethodInput
          formModel={formModel}
          paidInstallments={paidInstallments}
          loading={loading}
          editDismissalData={editDismissalData}
        />
      </div>

      <HiddenInput model={formModel} name={`installments_sum`} />

      {fields.map((payment, index) => (
        <div
          key={payment.id}
          className={`grid gap-y-3 gap-x-4 ${fields.length > 1 ? 'grid-cols-2' : 'grid-cols-1'}
                    ${index ? 'mt-0' : 'mt-2.5'} ${installmentsNumber ? '' : 'hidden'}`}
          id={`dismissal_installments.${index}.data`}
        >
          {fields.length > 1 &&
            <CurrencyInput
              model={model}
              name={`${index}.installment_value`}
              title={index ? '' : i18n.t("user_dismissal.installment_value")}
              className={'w-full'}
              requiredField={index ? false : true}
              disabled={String(getValues(`${model}.${index}.past_installment`)) === 'true'}
            />
          }
          <DateCustomInput
            fieldId={`${model}_${index}_payment_date`}
            model={model}
            name={`${index}.payment_date`}
            title={index ? '' : i18n.t("user_dismissal.payment_date")}
            requiredField={index ? false : true}
            className={"disabled:bg-border-gray disabled:text-placeholder-gray flex items-center"}
            divClassName={"py-2 pr-3.5 mt-2 text-md block w-full appearance-none border border-solid border-border-gray box-border rounded focus:border-gray-600 h-11 flex items-center"}
            titleClass={'font font-medium font-Inter leading-5 text-sm text-gray-700'}
            disabled={String(getValues(`${model}.${index}.past_installment`)) === 'true'}
          />

          <HiddenInput model={model} name={`${index}.installments_number`} />
          <HiddenInput model={model} name={`${index}.past_installment`} />
        </div>
      ))}
    </>
  )
}
