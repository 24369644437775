import React, { useState, useEffect } from "react";
import i18n from "#translate/i18n";
import { Trans } from "react-i18next";

import HealthcareIcon from '#images/events_icons/healthcare_updated.svg';
import ArrowDown from '#images/arrowdown-top.svg';
import EditIconBlue from "#images/edit-blue.svg";
import EditIconGray from "#images/edit-gray.svg";
import DeleteIconRed from "#images/delete-red.svg";
import DeleteIconGray from "#images/delete-gray.svg";
import TooltipMessageHoverButtons from "#components/TooltipMessageHoverButtons";

export default function HealthcareUpdated({
  eventData,
  lastUpdates,
  removedArray,
  setOpenRemovalConfirmation,
  setOpenRemovalSuccess,
  setObjectId,
  setChosenEventRemoval,
  showDetails,
  setShowDetails,
  openEditionDrawer,
  lastCreation,
  isDismissed
}) {
  const data = eventData?.data || {}
  const adminName = data.responsible?.full_name
  const currentValue = Number(data?.healthcares_current_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  const descendantsCount = (data?.descendants_event_data.length)
  const description = `timelineEvents.healthcareUpdated.description`

  const isRemoved = removedArray.some((removedItem) => removedItem.id === data.id && removedItem.creationEventName === 'HealthcareCreated')
  const eventDateTime = eventData?.date || ''
  const isTheLastUpdate = lastUpdates.some((update) => update.id === data.id && update.lastUpdatedOn === eventDateTime) && lastCreation('HealthcareCreated').data.id === data.id

  const [click, setClick] = useState(false)

  useEffect(() => {
    if(showDetails === 'close') { setClick(false) }
  }, [showDetails]);

  return (
    <>
      <div className="relative flex-1 my-5 bg-white">
        <div className="flex items-center">
          <div className='rounded-full shadow-timeline-icons mr-2 h-fit min-w-fit'>
            <img
              src={HealthcareIcon}
              alt="event icon"
              className='w-[52px] h-[52px] drop-shadow-[0.3333333432674408px_0.3333333432674408px_1.3333333730697632px_rgba(0,0,0,0.15)]'
            />
          </div>
          <div className="relative z-20 font-inter text-sm leading-[22px] text-info-gray flex-1">
            <p className="font-bold inline-block">
              {`${i18n.t("timelineEvents.healthcareUpdated.title")}`}
            </p>
            <p className="font-normal">
              <Trans
                i18nKey={`${i18n.t(description)}`}
                values={{ admin: adminName, currentValue: currentValue }}
              />
            </p>
          </div>
          <button
            id='healthcare-updated-details-button'
            className='ml-6 mb-3 p-2'
            onClick={() => { setClick(!click), setShowDetails(null), setOpenRemovalSuccess(false) }}
          >
            <img
              className={`w-3 h-[7.2px] right-0 ${click && 'transform rotate-180'} min-w-max`}
              src={ArrowDown}
              alt={i18n.t("dropdown_arrow_img")}
            />
          </button>
        </div>
        { click && (
          <>
            {descendantsCount === 0 ? (
                <div className="relative font-inter text-sm leading-6 text-info-gray mt-3 ml-14 w-full" id='healthcare-updated-details-panel'>
                  <p id="healthcare-updated-information">{`${i18n.t("timelineEvents.healthcareCreated.does_not_have_dependents")}`}</p>
                  <p id='healthcare-updated-documentation-presence'>
                    <span className="font-bold">{ i18n.t('timelineEvents.healthcareUpdated.document') }</span>
                    { i18n.t('timelineEvents.healthcareUpdated.documentPresent') }
                  </p>
                </div>
              ) : (
                <div className="relative font-inter text-sm leading-6 text-info-gray mt-3 ml-14" id='healthcare-updated-details-panel'>
                  <div className="flex w-full">
                    <div className="w-fit">
                      {data?.descendants_event_data.map((descendant, index) => (
                        <div key={index} id={`healthcare-updated-beneficiary-${index}`} className="flex items-center">
                          <div>
                            <p>
                              <span className="font-bold">
                                {`${i18n.t("timelineEvents.healthcareCreated.beneficiary")} ${index + 1}: `}
                              </span>
                              {descendant.full_name}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="w-fit ml-7">
                      {data?.descendants_event_data.map((descendant, index) => (
                        <div key={index} id={`healthcare-updated-value-${index}`} className="flex items-center ml-2">
                          <div>
                            <p>
                              <span className="font-bold">{`${i18n.t("timelineEvents.healthcareUpdated.value")}: `}</span>
                              {Number(descendant.healthcare_current_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <p id='healthcare-updated-documentation-presence'>
                    <span className="font-bold">{ i18n.t('timelineEvents.healthcareUpdated.document') }</span>
                    { i18n.t('timelineEvents.healthcareUpdated.documentPresent') }
                  </p>
                </div>
              )
            }

            {isTheLastUpdate &&  (
              <div className="flex space-x-4 justify-end mt-5" id="healthcare-updated-manage-buttons">
                <div className="flex space-x-4 group">
                  {isRemoved && (
                    <>
                      <TooltipMessageHoverButtons
                        buttonClass="absolute h-11 w-[390px] right-2"
                        tooltipClass="absolute bg-border-blue z-1 gap-1 text-white items-center text-center w-[490px] text-xs px-3 py-1 h-9 font-semibold
                                      rounded-lg bg-tooltip-color shadow-tooltip-buttons ml-[-40px]"
                        arrowClass='absolute ml-[-50px]'
                        messageClass="h-5 pt-1"
                        message={i18n.t('timelineEvents.removedMessage')}
                      />
                    </>
                  )}
                  <button
                    className={`justify-center font-sans font-semibold text-base bg-white
                    flex items-center px-4 py-2.5 ${isRemoved || isDismissed ? 'text-disabled-gray' : 'text-support-error'}`}
                    id='healthcare-updated-remove-button'
                    disabled={isRemoved || isDismissed}
                    onClick={() => { setOpenRemovalConfirmation(true), setObjectId(data.id), setChosenEventRemoval({name: 'HealthcareCreated'}), setShowDetails(null) }}
                  >
                    <img src={isRemoved || isDismissed ? DeleteIconGray : DeleteIconRed} alt="" className="inline mr-2 w-6 h-6" />
                    { i18n.t('timelineEvents.healthcareCreated.removeAllowance') }
                  </button>

                  <button
                    className={`justify-center font-sans font-semibold text-base rounded border flex items-center px-4 py-2.5
                                ${isRemoved ? 'text-disabled-gray bg-disabled-gray-button' : 'text-rebase-blue border-rebase-blue bg-white'}`}
                    id='healthcare-updated-edit-button'
                    disabled={isRemoved}
                    onClick={() => { openEditionDrawer(), setObjectId(data.id) }}
                  >
                    <img src={isRemoved ? EditIconGray : EditIconBlue} alt="" className="inline mr-2 w-6 h-6 stroke-rebase-blue" />
                    { i18n.t('timelineEvents.healthcareCreated.editAllowance') }
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
