/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState,useEffect } from "react";
import i18n from "#translate/i18n";
import SelectOptions from "#components/finances/SelectOptions";
import fetchDismissalPaymentMethods from "#requests/dismissals/fetchDismissalPaymentMethods"
import { useFormContext } from "react-hook-form";

export default function UserDismissalPaymentMethodInput({ formModel, paidInstallments, loading, editDismissalData }) {
  const [installmentsOptions, setInstallmentsOptions] = useState([]);
  const [maxInstallmentNumber, setMaxInstallmentNumber] = useState('')
  const { setValue } = useFormContext();

  useEffect(() => {
    fetchDismissalPaymentMethods()
      .then((parsedResponse) => {
        setMaxInstallmentNumber(Number(parsedResponse["max_installments_allowed"]))
      });
  }, []);

  useEffect(() => {
    const minInstallments = paidInstallments > 0 ? paidInstallments : 1
    if(!loading && maxInstallmentNumber) {
      setInstallmentsOptions(selectionOptions({ min: minInstallments, max: maxInstallmentNumber }))
    }
  }, [paidInstallments, maxInstallmentNumber, loading]);

  useEffect(() => {
    if(editDismissalData) {
      setValue(`${formModel}.installments_number`, editDismissalData.installments_number)
    }
  }, [installmentsOptions]);

  const selectionOptions = ({min, max}) => {
    const installmentsOptions = Array.from({ length: max - min + 1}, (_, i) => min + i);
    const options = installmentsOptions.map((installment) => {
      return(
        {
          id: `payment_${installment}_installments`,
          name: i18n.t(`${formModel}.installments_number.${installment}`),
          value: installment
        }
      )
    });

    return options
  }

  return (
    <SelectOptions
      model={formModel}
      name={'installments_number'}
      title={i18n.t(`${formModel}.installments_number.title`)}
      options={installmentsOptions}
      className={'w-full text-cyan-blue-medium-dark'}
      requiredField={true}
    />
  );
}
