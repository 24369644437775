import React, { useState, useRef } from "react";
import { Combobox } from "@headlessui/react";
import { useFormContext, get, Controller } from "react-hook-form";
import ArrowDown from "#images/arrow-down-gray.svg";
import ClearButton from "#images/close-button.svg";

import i18n from "#translate/i18n";

export default function BasicComboboxInput({
  inputOptions,
  optionNameKey,
  model,
  title,
  requiredField,
  errorProps,
  name: inputName,
  index,
  optionsI18nPrefix,
  shouldHide,
  onlySelection=false,
  selectionDisabled=false,
  classNameTitle,
  disabledText,
  availableIconMap,
  ...props
}) {

  const {
    formState: { errors },
    control,
    getValues,
    setValue,
  } = useFormContext();

  const [selectedOption, setSelectedOption] = useState(null);
  const [query, setQuery] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [click, setClick] = useState(false)
  const buttonRef = useRef()
  const inputRef = useRef()

  const name = model ? `${model}.${inputName}` : inputName;
  const fieldError = get(errors, name);
  const invalidClassName = fieldError ? "ring-2 ring-red-500" : "";
  const className = `${props.className || ''} ${invalidClassName}`;
  const currentSelectedOption = selectedOption ? selectedOption : getValues(name)

  const getFilteredOptions = () => {
    let options = [...inputOptions]
    if(query) {
      return options.filter((option) => {
        const currentOption = optionsI18nPrefix ? i18n.t(`${optionsI18nPrefix}.${option[optionNameKey]}`) : option[optionNameKey]
        return currentOption.toUpperCase().includes(query.toUpperCase())
      })
    } else {
      return options
    }
  }

  const filteredOptions = getFilteredOptions()

  const handleComboboxChange = (value, field) => {
    const currentValue = value ? value : null
    setSelectedOption(currentValue);
    field.onChange(currentValue);
    if(!value) {
      setQuery('')
    }
  };

  const handleComboboxInputClick = () => {
    buttonRef.current.click()
    setIsOpen(true);
  };

  const handleClearButtonClick = (e) => {
    e.preventDefault();
    clearQuery();
  };

  const handleComboboxButtonClick = () => {
    setClick(!click)
    setIsOpen(!isOpen)
  };

  const clearQuery = () => {
    setQuery('');
    setSelectedOption(null);
    setValue(name, '');
  };

  const displayCurrentValue = () => {
    return currentSelectedOption ?
            (optionsI18nPrefix ? i18n.t(`${optionsI18nPrefix}.${currentSelectedOption[optionNameKey]}`) : currentSelectedOption[optionNameKey]) :
            query
  }

  const ErrorComponent = ({ message, errorId }) => {
    return (
      <span
        {...errorProps}
        className="items-center font-medium tracking-wide text-red-500 text-xs -mt-1.5"
        role="alert"
        name="validationError"
        id={`${errorId}_error`}
      >
        {message}
      </span>
    );
  };

  ErrorComponent.displayName = "ErrorComponent";

  return (
    <>
      <p className={`font font-medium font-Inter leading-5 text-sm text-gray-700 mb-0 ${classNameTitle}`}>
        {title}
        {requiredField && (
          <span className="pl-1 text-red-500" id="required-field">
            *
          </span>
        )}
      </p>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Combobox
            disabled={selectionDisabled}
            value={selectedOption}
            onChange={(value) => handleComboboxChange(value, field)}
            nullable
          >
            <div className="relative">
            
              {currentSelectedOption && availableIconMap &&
                <img
                  className="absolute top-1 pl-3.5 py-1.5"
                  src={availableIconMap[currentSelectedOption[optionNameKey]]}
                  alt="Icon"
                />
              }

              <Combobox.Input
                ref={inputRef}
                id={index !== undefined ? `${model}_${index}_${optionNameKey}_input`: `${model}_${optionNameKey}_input`}
                className={`py-2 px-3.5 ${ currentSelectedOption && availableIconMap && 'pl-[46px]' } mt-2 text-md block rounded w-full
                            h-11 cursor-pointer bg-white border-border-gray text-cyan-blue-medium-dark placeholder-placeholder-gray
                            focus:placeholder-gray-500 focus:bg-white focus:border-gray-600 focus:outline-none
                            ${onlySelection && !selectionDisabled ? '': 'disabled:bg-border-gray disabled:text-placeholder-gray'}
                            ${shouldHide ? 'w-0 h-0 border-none focus:border-none appearance-none' : ''} ${className}`}
                placeholder="Selecione uma das opções"
                onChange={(event) => {
                  if(onlySelection) {
                    event.target.value = '';
                    event.target.disabled = true;
                    document.getElementById(event.target.id).style.caretColor = 'transparent';
                  } else {
                    setQuery(event.target.value)
                  }
                }}
                onClick={handleComboboxInputClick}
                onFocus={(event) => {
                  setIsOpen(true)
                  if(onlySelection) {
                    document.getElementById(event.target.id).style.caretColor = 'transparent';
                  }
                }}
                displayValue={() => displayCurrentValue()}
                />
              
              {(selectedOption || query) && !onlySelection && (
                <button
                  id={index !== undefined ? `clear-button-${index}` : `clear-button`}
                  onClick={handleClearButtonClick}
                  className="absolute right-4 top-1.5 px-4 py-2 rounded-md cursor-pointer"
                >
                  <img src={ClearButton} alt="Clear button" />
                </button>
              )}
              <Combobox.Button
                ref={buttonRef}
                onClick={handleComboboxButtonClick}
                className="absolute right-0 top-3 pl-2 pr-4 py-2 rounded"
                id={index !== undefined ? `${model}_${index}_${optionNameKey}_button` : `${model}_${index}_${optionNameKey}_button`}
              >
                <img
                  src={ArrowDown}
                  className={`w-3 h-[7.5px] select-input-arrow-filter`}
                  alt="Toggle Options"
                />
              </Combobox.Button>
            </div>
            <Combobox.Options
              className={`bg-white border border-border-blue rounded shadow-lg ${isOpen ? 'mt-2 py-1' : 'hidden'}`}
              id={index !== undefined ? `${model}_${index}_${optionNameKey}_options`: `${model}_${optionNameKey}_options`}
            >
              <>
                {
                  filteredOptions.map((option) => (
                    <Combobox.Option
                      className={({ active }) => `py-4 pr-4 pl-3.5 cursor-pointer text-base ${option.available ? 'text-cyan-blue-medium-dark hover:bg-light-gray hover:font-semibold' : 'text-placeholder-gray'} ${active && option.available ? 'bg-light-gray font-semibold' : ''} `}
                      key={`${option.id}_${option[optionNameKey]}_fields`}
                      value={option}
                      disabled={!option.available}
                    >
                      { availableIconMap &&
                        <img
                          className={`inline-block mr-2 ${!option.available && 'select-unavailable-icon-filter'}`}
                          src={availableIconMap[option[optionNameKey]]}
                          alt='Icon'
                        />
                      }
                      {optionsI18nPrefix ? i18n.t(`${optionsI18nPrefix}.${option[optionNameKey]}`) : option[optionNameKey]}
                      {!option.available && (<span className='font-bold italic'>{disabledText}</span>)}
                    </Combobox.Option>
                    )
                  )
                }
              </>
            </Combobox.Options>
          </Combobox>
        )}
      />
      {fieldError && <ErrorComponent
                       message={fieldError.message}
                       errorId={index !== undefined ? `${model}_${index}_${optionNameKey}_input` : `${model}_${optionNameKey}_input`}
                      />
      }
    </>
  );
}
