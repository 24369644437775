import React from "react"
import { Trans } from "react-i18next"
import i18n from "#translate/i18n"

import SuccessCheck from "#images/bonus_drawer/success_check.svg"
import CloseButton from "#images/bonus_drawer/close_button.svg"

export default function SuccessMessage({ username, source, setShowMessage, additionalData }) {
  return (
    <div className="rounded fixed w-[437px] h-32 right-10 bottom-8 shadow-[0_1px_2px_rgba(0,0,0,0.3),0_2px_6px_rgba(0,0,0,0.15)] bg-white text-dark-gray-scale"
      id='general-success-message'
      style={{
        zIndex: 2000
      }}
    >
      <div className="flex flex-row w-full h-full" id='general-success-message-body'>
        <div className="bg-light-support-positive h-full w-24 flex flex-row items-center" id='general-success-message-body-div'>
          <img src={SuccessCheck} alt={i18n.t('drawerResponses.success.check')} className="mx-auto" id='general-success-message-body-image' />
        </div>

        <div className="grow p-4" id='general-success-message-text'>
          <h1 className="font-semibold font-sans text-base" id='general-success-message-h1'>{i18n.t(`drawerResponses.success.${source}.title`)}</h1>
          <p className="font-sans font-normal text-sm mt-1" id='general-success-message-p'><Trans i18nKey={`drawerResponses.success.${source}.content`} values={{ name: username, additionalData: additionalData }} /></p>
        </div>
      </div>

      <button onClick={() => { setShowMessage({}) }} className="w-5 h-11 absolute top-0 right-3" id='general-success-message-close'>
        <img src={CloseButton} alt={i18n.t('drawerResponses.success.check')} id='general-success-message-close-img' />
      </button>
    </div>
  )
}