import { descendantFields, primaryEmergencyContactFields, emergencyContactFields } from "#data/userData";
import UserRequest from "#requests/UserRequest";
import FormatPhoneReceived from "../../FormatPhoneReceived";
import removeObjectNulls from "#services/removeObjectNulls";

const setupData = () => {
  let data = {};
  const setData = (value) => {
    typeof value === "function" ? (data = value(data)) : (data = value);
  };
  const getData = () => data;

  return [getData, setData];
};

const isSpecialCase = (key) => {
  const specialCases = [
    "face_picture",
    "body_picture",
    "rg_front",
    "rg_back",
    "cpf_front",
    "cpf_back",
    "cnpj_card",
    "residence_proof",
    "hobbies",
    "personal_preference",
    "contacts",
    "emergency_contacts",
    "company_history_records",
    "bank_accounts",
    "descendants",
  ];

  return specialCases.includes(key);
};

const handleSpecialCase = (key, response, addData) => {
  const handleIgnore = () => { };
  const handleHobbies = (key, response, addData) => {
    const value = response[key];
    const hobbyIds = response["hobbies"] ? response["hobbies"].map(hobby => hobby.id) : [];

    return addData((values) => ({
      ...values,
      ["personal_preference"]: {
        ...value,
        hobby_ids: hobbyIds,
      },
    }));
  };

  const handleDocument = (key, response, addData) => {
    const value = response[key];
    const pictures = ["face_picture", "body_picture"]
    const model = pictures.includes(key) ? "picture" : "user"

    addData((values) => ({
      ...values,
      [model]: { ...values[model], [key]: value ? value : '' , [`${key}_update`]:  value ? true : false  },
    }));
  };

  /*eslint-disable no-unused-vars*/
  const handleCollection = (key, response, addData) => {
    const value = response[key].map((item) => {
      const { id: _id, ...values } = item;

      return { ...values, [`${key}_id`]: item.id };
    });

    handleData(key, value, addData);
  };

  const handleEmergencyContacts = (key, response, addData) => {
    const value = response[key].map((item) => {
      const code = item.code;
      const { id: _id, ...values } = {
        ...item,
        [`${key}_id`]: item["id"],
        ...(code && { code })
      }


      return values
    });
  
    if (!value.length) {
      value.push(primaryEmergencyContactFields)  
      value.push(emergencyContactFields)  
    } else if (value.length === 1) {
      value.push(emergencyContactFields)
    }

    handleData(key, value, addData);
  };

  const handleDescendants = (key, response, addData) => {
    const value = response[key].map((item) => {
      const { id: _id, ...value } = {
        ...item,
        [`${key}_id`]: item["id"],
        destroy: "",
      };

      return value;
    });

    value.push(descendantFields)
    handleData(key, value, addData);
  };
  /*eslint-enable no-unused-vars*/

  const specialCases = {
    face_picture: handleDocument,
    body_picture: handleDocument,
    rg_front: handleDocument,
    rg_back: handleDocument,
    cpf_front: handleDocument,
    cpf_back: handleDocument,
    residence_proof: handleDocument,
    cnpj_card: handleDocument,
    hobbies: handleIgnore,
    personal_preference: handleHobbies,
    contacts: handleCollection,
    emergency_contacts: handleEmergencyContacts,
    company_history_records: handleCollection,
    bank_accounts: handleCollection,
    descendants: handleDescendants,
  };

  return specialCases[key](key, response, addData);
};

const handleData = (key, value, addData) => {
  addData((values) => ({ ...values, [key]: value }));
};

const handleUser = (key, value, addData) => {
  addData((values) => ({
    ...values,
    ["user"]: { ...values["user"], [key]: value },
  }));
};

export default function fillUserForm(userId, setForm) {
  const [data, addData] = setupData();

  const fillFields = async () => {
    const response = await UserRequest(userId);

    let responseFiltered = { ...response }
    let newContactsData = { 'contacts': response['contacts'].map((contact) => removeObjectNulls(contact))}
    let keys = Object.keys(newContactsData)
    keys.map(x => { responseFiltered[x] = newContactsData[x] })

    Object.keys(response).forEach((key) => {
      const value = response[key];
      if (isSpecialCase(key)) return handleSpecialCase(key, response, addData);

      if (value instanceof Array || value instanceof Object)
        return handleData(key, value, addData);

      if (value !== undefined && value !== null) {
        handleUser(key, value, addData);
      }
    });
    FormatPhoneReceived(responseFiltered, addData);

    setForm(data());
  };

  fillFields();
}
