import React from "react";
import i18n from "#translate/i18n";

const FillProgressCard = ({
  colors,
  backBarClass,
  icon,
  title,
  description,
  percentage,
}) => {
  const backBarColor = colors[0];
  const frontBarColor = colors[1];
  const fontColor = colors[2];

  return (
    <div className="bg-white w-76.3 h-64.4 p-4 pt-6 rounded-1.8xl ">
      <section className="grid justify-items-center gap-y-2.5">
        <div className="flex items-center justify-center bg-light-blue w-15 h-15 rounded-[50%]">
          <img src={icon} alt="" />
        </div>
        <h1 className="font-bold font-sans text-cyan-blue-medium-dark text-sm mb-4">
          {title}
        </h1>
      </section>
      <label className={`font-sans ${fontColor} text-xs`}>
        {`${percentage}% ${i18n.t("FillProgress.label")}`}
      </label>
      <div className={`${backBarClass} ${backBarColor}`}>
        <div
          className={`${frontBarColor} h-1`}
          style={{ width: `${percentage}%` }}
        />
      </div>
      <p className="font-sans text-night-rider text-2sm">{description}</p>
    </div>
  );
};

FillProgressCard.defaultProps = {
  backBarClass: "w-70.3 h-1 mb-4",
};

export default FillProgressCard;
