import React from "react";
import Field from "./Field";

const InputText = ({
  model,
  title,
  requiredField,
  subtitle,
  name,
  label,
  type,
  placeholder,
  maxLength,
  disabled,
  shouldSetValue,
  inviteFieldStyle,
  className,
  tooltip,
  shouldHide,
  ...props
}) => {
  const id = model ? `${model}_${name}_input` : `${name}_input`;

  return (
    <div id={`${model}_${name}`} className="flex flex-col justify-start">
      <Field
        {...props}
        inviteFieldStyle={inviteFieldStyle}
        model={model}
        label={
          <div className={`${shouldHide ? "w-0 h-0" : "" }`}>
            {shouldHide ? <></> :
              <>
                { label || title }

                { requiredField && (
                  <span 
                    className="pl-1 text-red-500"
                    id="required-field"
                  >
                    *
                  </span>
                )}

                { subtitle && (
                  <span className="pl-1">
                    (<span className="font-normal">{subtitle}</span>)
                  </span>
                )}

                {tooltip}
              </>
            }
          </div>
        }
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={shouldHide ? false : disabled}
        shouldSetValue={shouldSetValue}
        className={`${inviteFieldStyle ? inviteFieldStyle : "py-2 px-3 text-md block rounded mt-2 w-[102%] h-11 bg-white placeholder-placeholder-gray border border-solid border-border-gray rounded box-border focus:placeholder-gray-400 focus:bg-white focus:border-gray-600 focus:outline-none focus:ring-2 disabled:bg-border-gray disabled:text-placeholder-gray"} ${shouldHide ? 'w-0 h-0 border-none focus:border-none' : ''} ${className}`}
      />
    </div>
  );
};

InputText.defaultProps = {
  type: "text",
  placeholder: null,
  maxLength: null,
  disabled: false,
  className: "",
};

export default InputText;