import React from 'react';
import { registerLocale } from 'react-datepicker';
import i18n from '#translate/i18n';
import CalendarDark from '#images/calendar-dark-icon.svg';
import CalendarLight from '#images/calendar-light-icon.svg';
import DatePicker from 'react-datepicker';
import pt from 'date-fns/locale/pt';
registerLocale('pt', pt);

export default function RangeDate({ startDate, endDate, setDateRange, filterDate, ...props }) {
  const CalendarInput = React.forwardRef(({ value, onClick }, ref) => (
    <div className={`flex items-center w-[260px] h-10 px-2 rounded border-2 border-border-gray text-cyan-blue-medium-dark ${props.disabled ? 'bg-border-gray' : 'bg-white'}`}>
      <img
        className='pl-1.5 pr-2'
        src={props.disabled ? CalendarLight : CalendarDark}
        ref={ref}
        alt='mostra o campo de range de duas datas'
      />
      <input
        disabled={props.disabled}
        onClick={onClick}
        ref={ref}
        data-testid='component-range-date-input'
        id='component-range-date-input'
        placeholder={i18n.t('rangeDate.placeholder')}
        defaultValue={value}
        className={`focus:outline-none focus:ring-1 
        h-6 w-[196px] text-base border-0 border-bg-border-gray ${props.disabled ? 'bg-border-gray' : 'focus:ring-border-gray placeholder:text-cyan-blue-medium-dark bg-white text-cyan-blue-medium-dark z-[300]'}`}
      />
    </div>
  ));

  CalendarInput.displayName = 'CalendarInput';

  return (
    <>
      <DatePicker
        data-testid='range-date-picker'
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onCalendarClose={props.handleChange}
        locale='pt'
        dateFormat='dd/MM/yyyy'
        onChange={(update) => {
          setDateRange(update);
          props.handleChange(update)
        }}
        filterDate={filterDate ? filterDate : () => true}
        isClearable
        showYearDropdown
        customInput={<CalendarInput />}
      />
    </>
  );
}
