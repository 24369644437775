import React from "react";
import { useFormContext, useController, get } from "react-hook-form";
import CurrencyInputField from "react-currency-input-field";
import i18n from "#translate/i18n";

const CurrencyInput = ({
  model,
  title,
  name,
  requiredField,
  className,
  placeHolder,
  setOnChange,
  filledValue,
  automaticFilledValue=false,
  disabled=false,
  shouldHide,
  inviteFieldStyle,
}) => {
  const inputName = `${model}.${name}`;
  const id = `${model}_${name}_input`;
  const {
    formState: { errors },
  } = useFormContext();
  const {
    field: { onChange, name: fieldName, value, ref },
  } = useController({
    name: inputName,
  });
  const currentValue = (automaticFilledValue && filledValue) ? filledValue : value

  const fieldError = get(errors, inputName);
  const invalidClassName = (fieldError && "ring-2 ring-red-500") || "";

  return (
    <div id={`${model}_${name}`} className={`flex flex-col justify-end ${shouldHide ? "w-0 h-0" : "" }`}>
      { !shouldHide &&
        <label
          htmlFor={id}
          className={`font font-medium font-Inter leading-5 text-sm text-gray-700 
                      ${inviteFieldStyle ? "pb-[7px]" : "" }`}
        >
          {title}

          { requiredField && (
            <span 
              className="pl-1 text-red-500"
              id="required-field"
            >
              *
            </span>
          )}
        </label>
      }

      <CurrencyInputField
        id={id}
        value={currentValue}
        name={fieldName}
        placeholder={placeHolder}
        className={`${inviteFieldStyle ? inviteFieldStyle : "py-2 px-3 text-md block rounded mt-2 w-[101%] h-11 bg-white border border-solid border-border-gray rounded box-border placeholder-placeholder-gray focus:placeholder-gray-400 focus:bg-white focus:border-gray-600 focus:outline-none focus:ring-2 disabled:bg-border-gray disabled:text-placeholder-gray"} ${shouldHide ? 'w-0 h-0 border-none focus:border-none' : ''} ${className} ${invalidClassName} `}
        decimalsLimit={2}
        decimalScale={2}
        allowNegativeValue={false}
        intlConfig={{ locale: "pt-BR", currency: "BRL" }}
        disableAbbreviations={true}
        disabled={shouldHide ? false : disabled}
        onValueChange={(inputValue, fieldName, { float, value }) => {
          if(setOnChange) { setOnChange(value) }
          // 123,
          if (value.slice(-1) === ",") return onChange(value);

          // 123,0
          if (value.slice(-2) === ",0") return onChange(value);

          // 123,10
          if (value.slice(-1) === "0" && value.slice(-3, -2) === ",")
            return onChange(float.toFixed(2));

          return onChange(float);
        }}
        ref={ref}
      />

      {fieldError && (
        <span
          className="items-center font-medium tracking-wide text-red-500
          text-xs mt-1" role='alert' id={`${id}_error`}
        >
          {fieldError.message}
        </span>
      )}
    </div>
  );
};

CurrencyInput.defaultProps = {
  className: "",
  placeHolder: i18n.t("placeholders.currency_input"),
};

export default CurrencyInput;