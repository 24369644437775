import React from "react";
import i18n from "#translate/i18n";
import FilePreview from '#components/uploadFile/FilePreview'
import PTBRDateFormat from '#services/PTBRDateFormat';
import docIcon from "#images/profile_drawer/doc-icon.svg";


export default function DocumentsAttachments({ userData }) {
  const healthcareProofs = userData.healthcare_proof_docs
  const healthcareAmmount = healthcareProofs?.length

  const workLeaveDocumentation = userData.work_leaves_documentation
  const hasWorkLeaveDocumentation = workLeaveDocumentation?.length > 0

  return (
    <div className="mx-6 mt-5">
      <div className="grid grid-cols-2 gap-y-5 gap-x-5">
        <div>
          <p className="text-info-gray text-sm font-semibold" >{i18n.t('userProfile.documents_tab.documents_attachments.face_picture')}</p>
          <FilePreview data={userData.face_picture} emptyDocumentImage={docIcon} altEmpty='empty_face_picture' name='face_picture' />
        </div>
        <div>
          <p className="text-info-gray text-sm font-semibold" >{i18n.t('userProfile.documents_tab.documents_attachments.body_picture')}</p>
          <FilePreview data={userData.body_picture} emptyDocumentImage={docIcon} altEmpty='empty_body_picture' name='body_picture' />
        </div>
        <div>
          <p className="text-info-gray text-sm font-semibold" >{i18n.t('userProfile.documents_tab.documents_attachments.cnpj_card')}</p>
          <FilePreview data={userData.cnpj_card} emptyDocumentImage={docIcon} altEmpty='empty_cnpj_card' name='cnpj_card' />
        </div>
        <div>
          <p className="text-info-gray text-sm font-semibold" >{i18n.t('userProfile.documents_tab.documents_attachments.residence_proof')}</p>
          <FilePreview data={userData.residence_proof} emptyDocumentImage={docIcon} altEmpty='empty_residence_proof' name='residence_proof' />
        </div>
        <div>
          <p className="text-info-gray text-sm font-semibold" >{i18n.t('userProfile.documents_tab.documents_attachments.rg_front')}</p>
          <FilePreview data={userData.rg_front} emptyDocumentImage={docIcon} altEmpty='empty_rg_front' name='rg_front' />
        </div>
        <div>
          <p className="text-info-gray text-sm font-semibold" >{i18n.t('userProfile.documents_tab.documents_attachments.rg_back')}</p>
          <FilePreview data={userData.rg_back} emptyDocumentImage={docIcon} altEmpty='empty_rg_back' name='rg_back' />
        </div>
        <div>
          <p className="text-info-gray text-sm font-semibold" >{i18n.t('userProfile.documents_tab.documents_attachments.cpf_front')}</p>
          <FilePreview data={userData.cpf_front} emptyDocumentImage={docIcon} altEmpty='empty_cpf_front' name='cpf_front' />
        </div>
        <div>
          <p className="text-info-gray text-sm font-semibold" >{i18n.t('userProfile.documents_tab.documents_attachments.cpf_back')}</p>
          <FilePreview data={userData.cpf_back} emptyDocumentImage={docIcon} altEmpty='empty_cpf_back' name='cpf_back' />
        </div>
        {healthcareAmmount ? userData.healthcare_proof_docs.map((healthcare, index) => (
          <div key={`healthcare-${index}`}>
            <p className="text-info-gray text-sm font-semibold" >{
              healthcareAmmount === 1 ?
                i18n.t('userProfile.documents_tab.documents_attachments.healthcare') :
                `${i18n.t('userProfile.documents_tab.documents_attachments.healthcare')} - ${index + 1}`
            }</p>
            <FilePreview data={healthcare} emptyDocumentImage={docIcon} altEmpty='empty_healthcare' name={`${index}.healthcare`} />
          </div>
        )) :
          <div>
            <p className="text-info-gray text-sm font-semibold" >{i18n.t('userProfile.documents_tab.documents_attachments.healthcare')}</p>
            <FilePreview data={{}} emptyDocumentImage={docIcon} altEmpty={'empty_healthcare'} name='healthcare' />
          </div>
        }
        {hasWorkLeaveDocumentation && (userData.work_leaves_documentation.map((workLeave, index) => (
          <div key={`workLeave-${index}`}>
            <p className="text-info-gray text-sm font-semibold" >{
                `${i18n.t('userProfile.documents_tab.documents_attachments.work_leave')} - ${PTBRDateFormat(workLeave.start_date)}`
            }</p>
            <FilePreview data={workLeave.file_data} emptyDocumentImage={docIcon} altEmpty='empty_work_leave' name={`${index}.work_leave`} />
          </div>
          )))
        }
      </div>
    </div>
  )
}
