import React from "react";
import { useFormContext, get } from "react-hook-form";

const Field = ({
  model,
  name: inputName,
  label,
  labelProps,
  errorComponent: ErrorComponent,
  errorProps,
  onChange,
  onBlur,
  component: InputComponent,
  shouldSetValue,
  inviteFieldStyle,
  uploadFileInput,
  icon,
  ...props
}) => {
  const name = model ? `${model}.${inputName}` : inputName;
  const id = props.id || name;
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { setValue } = useFormContext();
  const fieldError = get(errors, name);
  const invalidClassName = (fieldError && "ring-2 ring-red-500") || "";
  const className = `${props.className} ${invalidClassName}`;
  const customOnChange = onChange ? { onChange: onChange } : {};
  const customOnBlur = onBlur ? { onBlur: onBlur } : {};
  const customSetValueOnChange = shouldSetValue ? setValue(name, props.valuetoset) : {}

  return (
    <>
      {label && (
        <label htmlFor={id} {...labelProps}>
          <div className={`${inviteFieldStyle ? "pb-[7px]" : "" }`}>
            {label}
          </div>
        </label>
      )}

      <div className="flex-col">
        { icon &&
          <img
            className="w-5 h-5 absolute ml-3 my-5"
            src={icon}
            alt='Icon field'
          />
        }

        <InputComponent
          {...props}
          style={props.inputstyle}
          {...register(name, { ...customOnChange, ...customSetValueOnChange, ...customOnBlur })}
          id={id}
          className={className}
        />

        {fieldError && !uploadFileInput && (
          <ErrorComponent {...errorProps} role="alert" id={`${id}_error`}>
            {fieldError.message}
          </ErrorComponent>
        )}
      </div>
    </>
  );
};

Field.defaultProps = {
  model: "",
  component: "input",
  errorComponent: "span",
  errorProps: {
    name: "validationError",
    className:
      "items-center font-medium tracking-wide text-red-500 text-xs mt-1",
  },
  labelProps: {
    className: "font font-medium font-Inter leading-5 text-sm text-gray-700",
  },
};

export default Field;
