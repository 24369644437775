/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Menu } from '@headlessui/react';
import yearEndBonusIcon from '#images/add_allowances/year_end_bonus/menu_icon.svg'
import yearEndBonusDisabledIcon from '#images/add_allowances/year_end_bonus/menu_icon_disabled.svg'
import homeOfficeAssistanceIcon from '#images/add_allowances/home_office/menu_icon.svg'
import homeOfficeAssistanceDisabledIcon from '#images/add_allowances/home_office/menu_icon_disabled.svg'
import mealIcon from '#images/add_allowances/meal/menu_icon.svg'
import mealDisabledIcon from '#images/add_allowances/meal/menu_icon_disabled.svg'
import parentalLeaveIcon from '#images/add_allowances/parental_leave/menu_icon.svg'
import parentalLeaveDisabledIcon from '#images/add_allowances/parental_leave/menu_icon_disabled.svg'
import healthcareIcon from '#images/add_allowances/healthcare/menu_icon.svg'
import healthcareDisabledIcon from '#images/add_allowances/healthcare/menu_icon_disabled.svg'
import vacationIcon from '#images/add_allowances/vacation_drawer/icon.svg'
import psychologicalSupportIcon from '#images/add_allowances/psychological_support/menu_icon.svg'
import psychologicalSupportDisabledIcon from '#images/add_allowances/psychological_support/menu_icon_disabled.svg'
import menuArrow from '#images/add_allowances/menu_arrow.svg'
import i18n from '#translate/i18n';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function AllowancesMenu({
  chosenAllowance,
  registeredAllowances,
  editObjectId,
  ...props
}) {
  const homeOfficeImage = checkDisabledStatus('home_office') ? homeOfficeAssistanceDisabledIcon : homeOfficeAssistanceIcon
  const mealImage = checkDisabledStatus('meal') ? mealDisabledIcon : mealIcon
  const yearEndBonusImage = checkDisabledStatus('year_end_bonus') ? yearEndBonusDisabledIcon : yearEndBonusIcon
  const parentalLeaveImage = checkDisabledStatus('parental_leave') ? parentalLeaveDisabledIcon : parentalLeaveIcon
  const healthcareImage = checkDisabledStatus('healthcare') ? healthcareDisabledIcon : healthcareIcon
  const psychologicalSupportImage = checkDisabledStatus('psychological_support') ? psychologicalSupportDisabledIcon : psychologicalSupportIcon
  const textColor = chosenAllowance ? 'text-disabled-dark-gray' : 'text-[#96A0AA]'
  const images = {
    home_office: homeOfficeAssistanceIcon,
    meal: mealIcon,
    year_end_bonus: yearEndBonusIcon,
    parental_leave: parentalLeaveIcon,
    healthcare: healthcareIcon,
    psychological_support: psychologicalSupportIcon,
    vacation: vacationIcon
  }

  function buildItemStyle(disabled) {
    const itemStyle = 'absolute left-10 h-5 w-auto font-sans not-italic font-semibold text-sm leading-[2px]'
    return `${itemStyle} ${disabled ? 'text-[#96A0AA]' : 'text-cyan-blue-medium-dark'}`
  }

  function chosenDrawer(name) {
    props.setChosenAllowance(name)
    props.setDisableSubmitButton(false)
    props.setOpenMealAllowanceDrawer(name === 'meal')
    props.setOpenPsychologicalSupportDrawer(name === 'psychological_support')
    props.setOpenHomeOfficeAllowanceDrawer(name === 'home_office')
    props.setOpenYearEndBonusDrawer(name === 'year_end_bonus')
    props.setOpenHealthcareDrawer(name === 'healthcare')
    props.setOpenParentalLeaveDrawer(name === 'parental_leave')
    props.setOpenVacationDrawer(name === 'vacation')
  }

  function checkDisabledStatus(allowanceName) {
    const currentAllowance = registeredAllowances.find(({ name }) => name === allowanceName)
    return currentAllowance ? !currentAllowance.available : true
  }

  return (
    <div className='p-0 my-1'
    >
      <Menu as='div' className='relative z-[1600]' id='menu_add_allowance'>
        <Menu.Button
          className={`relative flex flex-row items-center py-2.5 px-3.5 gap-2 h-11 w-full border border-solid
                      border-border-gray rounded box-border order-1 
                      ${editObjectId ? 'bg-border-gray' : 'bg-white hover:border-border-blue'}`}
          name='handle_menu_add_allowance_button'
          data-testid='handle_menu_add_allowance_button'
          id='handle_menu_add_allowance_button'
          disabled={editObjectId ? true : false}
        >
          {
            chosenAllowance ?
              <div className={`font font-sans font-medium text-sm text-base ${editObjectId ? 'text-disabled-dark-gray' : textColor} flex flex-row`}>
                <img
                  src={images[chosenAllowance]}
                  alt='Chosen Allowance'
                />
                <p className='px-2'>{i18n.t(`add_allowances.${chosenAllowance}`)}</p>
              </div>
              : <p> {i18n.t(`add_allowances.select_allowance`)} </p>
          }
          <img
            className='absolute right-2'
            src={menuArrow}
            alt='Arrow'
          />
        </Menu.Button>
        <div id='add_allowances_list'>
          <Menu.Items
            className='absolute rounded bg-white grid grid-cols-1 ml-0 mt-1 w-full z-[1700]'
          >
            <div id='add_allowances_options'
              className='py-1 relative border shadow-[0px_1px_2px_rgba(0,0,0,0.3),0px_2px_6px_rgba(0,0,0,0.15)]
                border-solid border-border-blue rounded h-[150px] lg:h-[250px] xl:h-[300px] 2xl:h-fit overflow-y-auto'
            >
              <Menu.Item className='flex flex-row items-center h-14 w-full hover:bg-light-gray'>
                {({ active }) => (
                  <div>
                    <button
                      className={
                        `${classNames(active ? 'bg-gray-100 z-10' : '', 'flex text-sm w-full h-[56px] pl-4 py-4 left-[0px]')}
                          ${buildItemStyle(checkDisabledStatus('meal'))}`
                      }
                      disabled={checkDisabledStatus('meal')}
                      id='add_meal_allowance_menu_button'
                      onClick={() => chosenDrawer('meal')}
                    >
                      <img
                        className='flex mr-4 z-20'
                        src={mealImage}
                        alt='Meal Allowance Icon'
                      />
                      <span className="mt-3">
                        {
                          checkDisabledStatus('meal') ?
                            <>
                              {i18n.t('add_allowances.meal')} - <span className='font-bold italic'>{i18n.t('add_allowances.disabled')}</span>
                            </>
                            : <> {i18n.t('add_allowances.meal')} </>
                        }
                      </span>
                    </button>
                  </div>
                )}
              </Menu.Item>
              <Menu.Item className='flex flex-row items-center h-14 w-full hover:bg-light-gray'>
                {({ active }) => (
                  <div>
                    <button
                      className={
                        `${classNames(active ? 'bg-gray-100 z-10' : '', 'flex text-sm w-full h-[56px] pl-4 py-4 left-[0px]')}
                          ${buildItemStyle(checkDisabledStatus('psychological_support'))}`
                      }
                      disabled={checkDisabledStatus('psychological_support')}
                      id='add_psychological_support_menu_button'
                      onClick={() => chosenDrawer('psychological_support')}
                    >
                      <img
                        className='flex mr-4 z-20'
                        src={psychologicalSupportImage}
                        alt='Psychological Support Icon'
                      />
                    <span className="mt-3">
                      {
                        checkDisabledStatus('psychological_support') ?
                          <>
                            {i18n.t('add_allowances.psychological_support')} - <span className='font-bold italic'>{i18n.t('add_allowances.disabled')}</span>
                          </>
                          : <> {i18n.t('add_allowances.psychological_support')} </>
                      }
                      </span>
                    </button>
                  </div>
                )}
              </Menu.Item>
              <Menu.Item className='flex flex-row items-center h-14 w-full hover:bg-light-gray'>
                {({ active }) => (
                  <div>
                    <button
                      className={
                        `${classNames(active ? 'bg-gray-100 z-10' : '', 'flex text-sm w-full h-[56px] pl-4 py-4 left-[0px]')}
                          ${buildItemStyle(checkDisabledStatus('home_office'))}`
                      }
                      disabled={checkDisabledStatus('home_office')}
                      id='add_home_office_allowance_menu_button'
                      onClick={() => chosenDrawer('home_office')}
                    >
                      <img
                        className='flex mr-4 z-20'
                        src={homeOfficeImage}
                        alt='Home Office Assistance Icon'
                      />
                      <span className="mt-3">
                        {
                          checkDisabledStatus('home_office') ?
                            <>
                              {i18n.t('add_allowances.home_office')} - <span className='font-bold italic'>{i18n.t('add_allowances.disabled')}</span>
                            </>
                            : <> {i18n.t('add_allowances.home_office')} </>
                        }
                      </span>
                    </button>
                  </div>
                )}
              </Menu.Item>
              <Menu.Item className='flex flex-row items-center h-14 w-full hover:bg-light-gray'>
                {({ active }) => (
                  <div>
                    <button
                      className={
                        `${classNames(active ? 'bg-gray-100 z-10' : '', 'flex text-sm w-full h-[56px] pl-4 py-4 left-[0px]')}
                        ${buildItemStyle(checkDisabledStatus('year_end_bonus'))}`
                      }
                      disabled={checkDisabledStatus('year_end_bonus')}
                      id='add_year_end_bonus_menu_button'
                      onClick={() => chosenDrawer('year_end_bonus')}
                    >
                      <img
                        className='flex mr-4 z-20'
                        src={yearEndBonusImage}
                        alt='Year End Bonus Icon'
                      />
                      <span className="mt-3">
                        {
                          checkDisabledStatus('year_end_bonus') ?
                            <>
                              {i18n.t('add_allowances.year_end_bonus')} - <span className='font-bold italic'>{i18n.t('add_allowances.disabled')}</span>
                            </>
                            : <> {i18n.t('add_allowances.year_end_bonus')} </>
                        }
                      </span>
                    </button>
                  </div>
                )}
              </Menu.Item>
              <Menu.Item className='flex flex-row items-center h-14 w-full hover:bg-light-gray'>
                {({ active }) => (
                  <div>
                    <button
                      className={
                        `${classNames(active ? 'bg-gray-100 z-10' : '', 'flex text-sm w-full h-[56px] pl-4 py-4 left-[0px]')}
                        ${buildItemStyle(checkDisabledStatus('vacation'))}`
                      }
                      disabled={checkDisabledStatus('vacation')}
                      id='add_vacation'
                      onClick={() => chosenDrawer('vacation')}
                    >
                      <img
                        className='flex mr-4 z-20'
                        src={vacationIcon}
                        alt='Vacation Request Icon'
                      />
                      <span className="mt-3">
                      {
                        checkDisabledStatus('vacation') ?
                          <>
                            {i18n.t('add_allowances.vacation')} - <span className='font-bold italic'>{i18n.t('add_allowances.disabled')}</span>
                          </>
                          : <> {i18n.t('add_allowances.vacation')} </>
                      }
                      </span>
                    </button>
                  </div>
                )}
              </Menu.Item>
              <Menu.Item className='flex flex-row items-center h-14 w-full hover:bg-light-gray'>
                {({ active }) => (
                  <div>
                    <button
                      className={
                        `${classNames(active ? 'bg-gray-100 z-10' : '', 'flex text-sm w-full h-[56px] pl-4 py-4 left-[0px]')}
                        ${buildItemStyle(checkDisabledStatus('healthcare'))}`
                      }
                      disabled={checkDisabledStatus('healthcare')}
                      id='add_healthcare_menu_button'
                      onClick={() => chosenDrawer('healthcare')}
                    >
                      <img
                        className='flex mr-4 z-20'
                        src={healthcareImage}
                        alt='Healthcare Icon'
                      />
                      <span className="mt-3">

                        {
                          checkDisabledStatus('healthcare') ?
                            <>
                              {i18n.t('add_allowances.healthcare')} - <span className='font-bold italic'>{i18n.t('add_allowances.disabled')}</span>
                            </>
                            : <> {i18n.t('add_allowances.healthcare')} </>
                        }
                      </span>
                    </button>
                  </div>
                )}
              </Menu.Item>
              <Menu.Item className='flex flex-row items-center h-14 w-full hover:bg-light-gray'>
                {({ active }) => (
                  <div>
                    <button
                      className={
                        `${classNames(active ? 'bg-gray-100 z-10' : '', 'flex text-sm w-full h-[56px] pl-4 py-4 left-[0px]')}
                        ${buildItemStyle(checkDisabledStatus('parental_leave'))}`
                      }
                      disabled={checkDisabledStatus('parental_leave')}
                      id='add_parental_leave_menu_button'
                      onClick={() => chosenDrawer('parental_leave')}
                    >
                      <img
                        className='flex mr-4 z-20'
                        src={parentalLeaveImage}
                        alt='Parental Leave Icon'
                      />
                      <span className="mt-3">
                        {
                          checkDisabledStatus('parental_leave') ?
                            <>
                              {i18n.t('add_allowances.parental_leave')} - <span className='font-bold italic'>{i18n.t('add_allowances.disabled')}</span>
                            </>
                            : <> {i18n.t('add_allowances.parental_leave')} </>
                        }
                      </span>
                    </button>
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </div>
      </Menu>
    </div>
  );
}
