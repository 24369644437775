import React, { useState, useEffect } from "react";
import HomeOfficeUpadatedIcon from '#images/events_icons/home_office_updated.svg';
import i18n from "#translate/i18n";
import { Trans } from "react-i18next"
import TooltipMessageHoverButtons from "#components/TooltipMessageHoverButtons";
import ArrowDown from '#images/arrowdown-top.svg';
import EditIconBlue from "#images/edit-blue.svg"
import EditIconGray from "#images/edit-gray.svg"
import DeleteIconRed from "#images/delete-red.svg"
import DeleteIconGray from "#images/delete-gray.svg"
import userPresentedName from '#services/userPresentedName';

export default function HomeOfficeUpdated({
  eventData,
  userData,
  removedArray,
  lastUpdates,
  setOpenRemovalConfirmation,
  setOpenRemovalSuccess,
  setObjectId,
  setChosenEventRemoval,
  showDetails,
  setShowDetails,
  lastCreation,
  openEditionDrawer,
  isDismissed
}) {
  const data = eventData?.data || {}
  const eventUserData = data.user || userData
  const userName = userPresentedName(eventUserData)
  const eventDateTime = eventData.date || {};
  const isRemoved = removedArray.some((removedItem) => removedItem.id === data.id && removedItem.creationEventName === 'HomeOfficeCreated')
  const isTheLastUpdate = lastUpdates.some((update) => update.id === data.id && update.lastUpdatedOn === eventDateTime) && lastCreation('HomeOfficeCreated').data.id === data.id
  const adminName = data.responsible?.full_name
  const homeOfficeValue = Number(data.configuration?.monthly_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  const [click, setClick] = useState(false)

  useEffect(() => {
    if (showDetails === 'close') { setClick(false) }
  }, [showDetails]);

  return (
    <div className="relative flex-1 my-5 bg-white">
      <div className="flex items-center">
        <div className='rounded-full shadow-timeline-icons mr-2 h-fit min-w-fit'>
          <img
            src={HomeOfficeUpadatedIcon}
            alt="event icon"
            className='w-[52px] h-[52px] drop-shadow-[0.3333333432674408px_0.3333333432674408px_1.3333333730697632px_rgba(0,0,0,0.15)]
                        fill-[var(--suporte-erro-background,#F9E0E0)]'
          />
        </div>
        <div className="relative z-20 font-inter text-sm leading-[22px] text-info-gray flex-1">
          <p className="font-bold inline-block">
            {`${i18n.t("timelineEvents.homeOfficeUpdated.title")}`}
          </p>
          <p className="font-normal" id="home-office-updated-description">
            <Trans
              i18nKey={`${i18n.t("timelineEvents.homeOfficeUpdated.description")}`}
              values={{ admin: adminName, userName: userName, homeOfficeValue: homeOfficeValue }}
            />
          </p>
        </div>
        <button
          id='home-office-updated-details-button'
          className='ml-6 mb-3 p-2'
          onClick={() => { setClick(!click), setShowDetails(null), setOpenRemovalSuccess(false) }}
        >
          <img
            className={`w-3 h-[7.2px] right-0 ${click && 'transform rotate-180'} min-w-max`}
            src={ArrowDown}
            alt="menu arrow"
          />
        </button>
      </div>
      {click && (
        <>
          <div className="relative font-inter text-sm leading-6 text-info-gray mt-3 ml-14" id='home-office-updated-details-panel'>
            <p className="font-bold">
              {`${i18n.t("timelineEvents.homeOfficeUpdated.observations")}`}
            </p>
            <p id="home-office-notes">{data.notes || i18n.t("timelineEvents.homeOfficeUpdated.missingNotes")}</p>
          </div>
          {isTheLastUpdate && (
            <div className="flex space-x-4 justify-end mt-5" id='home-office-updated-manage-buttons'>
              <div className="flex space-x-4 group">
                {isRemoved && (
                  <>
                    <TooltipMessageHoverButtons
                      buttonClass="absolute h-11 w-[390px] right-2"
                      tooltipClass="absolute bg-border-blue z-1 gap-1 text-white items-center text-center w-[490px] text-xs px-3 py-1 h-9 font-semibold
                                    rounded-lg bg-tooltip-color shadow-tooltip-buttons ml-[-40px]"
                      arrowClass='absolute ml-[-50px]'
                      messageClass="h-5 pt-1"
                      message={i18n.t('timelineEvents.removedMessage')}
                    />
                  </>
                )}
                <button
                  className={`justify-center font-sans font-semibold text-base bg-white flex items-center px-4 py-2.5
                             ${isRemoved || isDismissed ? 'text-disabled-gray' : 'text-support-error'}`}
                  id="home-office-updated-remove-button"
                  disabled={isRemoved || isDismissed}
                  onClick={() => { setOpenRemovalConfirmation(true), setObjectId(data.id), setChosenEventRemoval({ name: 'HomeOfficeCreated' }), setShowDetails(null) }}
                >
                  <img src={isRemoved || isDismissed ? DeleteIconGray : DeleteIconRed} alt="delete icon" className="inline mr-2 w-6 h-6" />
                  {`${i18n.t("timelineEvents.homeOfficeUpdated.removeAssistance")}`}
                </button>

                <button
                  className={`justify-center font-sans font-semibold text-base rounded border flex items-center px-4 py-2.5
                             ${isRemoved || isDismissed ? 'text-disabled-gray bg-disabled-gray-button' : 'text-rebase-blue border-rebase-blue bg-white'}`}
                  id="home-office-updated-edit-button"
                  disabled={isRemoved || isDismissed}
                  onClick={() => { openEditionDrawer(), setObjectId(data.id) }}
                >
                  <img src={isRemoved || isDismissed ? EditIconGray : EditIconBlue} alt="edit icon" className="inline mr-2 w-6 h-6 stroke-rebase-blue" />
                  {`${i18n.t("timelineEvents.homeOfficeUpdated.editAssistance")}`}
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
