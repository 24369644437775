import React from "react";
import calendarIcon from "#images/calendar-light-icon.svg";
import DatePicker, { registerLocale } from "react-datepicker";
import { useFormContext, get, Controller } from "react-hook-form";
import pt from 'date-fns/locale/pt';
import { parseISO } from 'date-fns'; 
registerLocale('pt', pt);

export default function DateCustomInput({
  model,
  title,
  requiredField,
  dataTestId,
  name: inputName,
  errorProps,
  dateInviteStyle,
  fieldId,
  labelProps,
  ...props
}) {
  const name = model ? `${model}.${inputName}` : inputName;
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const fieldError = get(errors, name);

  const invalidClassName = fieldError ? "ring-2 ring-red-500" : "";
  const className = `${props.className || ''}`;

  const ErrorComponent = ({ message, errorId }) => {
    return (
      <span
        id={errorId}
        {...errorProps}
        className="items-center font-medium tracking-wide text-red-500 text-xs mt-1"
        role="alert"
        name="validationError"
      >
        {message}
      </span>
    );
  };

  ErrorComponent.displayName = "ErrorComponent";

  return (
    <div>
      <label htmlFor={fieldId} {...labelProps}>
        <div>
          <p className={`${dateInviteStyle ? "text-cyan-blue-medium-dark text-xs font-sans leading-5 font-bold pt-[1px] pb-[7px]" : ""} ${props.titleClass}`} >
            {title}

            { requiredField && (
                  <span 
                    className="pl-1 text-red-500"
                    id="required-field"
                  >
                    *
                  </span>
            )}

          </p>
        </div>
      </label>
      <div
        data-testid={dataTestId}
        className={`
          ${props.disabled ? 'bg-border-gray text-placeholder' : 'rounded-md border border-border-gray bg-white'}
          ${dateInviteStyle ? `${dateInviteStyle} text-cyan-blue-medium-dark` : ""}
          ${props.divClassName}
          ${invalidClassName}
          focus-within:ring-2 focus-within:ring-focus-blue
       `}
      >
        <img
          className="pl-3.5 mr-2"
          src={calendarIcon}
          alt="calendar icon"
        />
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <DatePicker
              {...props}
              className={`focus:ring-0 h-full w-full text-base border-0 placeholder:text-placeholder-gray text-black pl-0
                          ${className}`}
              wrapperClassName="w-full"
              data-testid="calendar-date-picker"
              id={fieldId ? fieldId : dataTestId}
              selected={(() => {
                try {
                  if (!field.value) {  return null }

                  // If using showMonthYearPicker, ensure the initial value is correctly parsed
                  const initialValue = typeof field.value === 'string' ? parseISO(field.value) : field.value;
                  return initialValue;
                } catch (error) {
                  console.error('Error parsing initial value:', error);
                  return null;
                }
              })()}
              onChange={(date) => { field.onChange(date) }}
              locale="pt"
              dateFormat={props.dateFormat ? props.dateFormat : "dd/MM/yyyy"}
              placeholderText={props.placeholderText ? props.placeholderText : "dd/mm/aaaa"}
              dateFormatCalendar={props.dateFormatCalendar ? props.dateFormatCalendar : "MMMM"}
              showYearDropdown
              yearDropdownItemNumber={70}
              scrollableYearDropdown
            />
          )}
        />
      </div>
      {fieldError && <ErrorComponent message={fieldError.message} errorId={`${fieldId ? fieldId : dataTestId}_error`}/>}
    </div>
  );
}
