import React, { useState, useEffect } from "react";
import i18n from "#translate/i18n";
import DivisionTitle from "#components/formComponents/DivisionTitle";
import SelectInput from "#components/formComponents/SelectInput";
import tShirtImg from "#images/referenceModal/t_shirt_size_reference.png";
// import sweatshirtImg from "#images/referenceModal/sweatshirt_size_reference.png";
// import pantsImg from "#images/referenceModal/pants_size_reference.png";
import shoeImg from "#images/referenceModal/shoe_size_reference.png";
import ReferenceModal from "../modals/ReferenceModal";
import fetchClothings from "#requests/fetchClothings";

export default function ClothingFields() {
  const model = "clothing";
  const [t_shirt, setTshirtSize] = useState(['']);
  const [shoe, setShoeSize] = useState(['']);
  // const [sweatshirt, setSweatshirtSize] = useState(['']);
  // const [pants, setPantsSize] = useState(['']);

  useEffect(() => {
    fetchClothings()
      .then((parsedResponse) => {
        setTshirtSize(parsedResponse["t_shirt_sizes"]);
        setShoeSize(parsedResponse["shoe_sizes"]);
        // setSweatshirtSize(parsedResponse["sweatshirt_sizes"]);
        // setPantsSize(parsedResponse["pants_sizes"]);
    });
  }, []);
  
  return (
    <div>
      <DivisionTitle title={i18n.t("clothing.clothing")} />
      <div className="grid grid-cols-2 gap-y-3 gap-x-9 my-3">
        <SelectInput
          model={model}
          name="t_shirt_size"
          title={i18n.t("clothing.t_shirt_size.title")}
          options={t_shirt}
          requiredField={true}
          optionsI18nPrefix={"clothing"}
          modalComponent={
            <ReferenceModal
              title={i18n.t("clothing.t_shirt_size.modal.title")}
              description={i18n.t("clothing.t_shirt_size.modal.description")}
              image={tShirtImg}
              alt={i18n.t("clothing.t_shirt_size.alt")}
            />
          }
          className='w-full'
        />

        <SelectInput
          model={model}
          name="shoe_size"
          title={i18n.t("clothing.shoe_size.title")}
          options={shoe}
          requiredField={true}
          optionsI18nPrefix={"clothing"}
          modalComponent={
            <ReferenceModal
              title={i18n.t("clothing.shoe_size.modal.title")}
              description={i18n.t("clothing.shoe_size.modal.description")}
              image={shoeImg}
              alt={i18n.t("clothing.shoe_size.alt")}
              testIdReference={"reference-modal-shoe"}
            />
          }
          className='w-full'
        />


        {/* <SelectInput
          model={model}
          title={i18n.t("clothing.sweatshirt_size.title")}
          name="sweatshirt_size"
          options={sweatshirt}
          requiredField={true}
          optionsI18nPrefix={"clothing"}
          modalComponent={
            <ReferenceModal
              title={i18n.t("clothing.sweatshirt_size.modal.title")}
              description={i18n.t("clothing.sweatshirt_size.modal.description")}
              image={sweatshirtImg}
              alt={i18n.t("clothing.sweatshirt_size.alt")}
            />
          }
          className='w-full'
        />

        <SelectInput
          model={model}
          title={i18n.t("clothing.pants_size.title")}
          name="pants_size"
          options={pants}
          requiredField={false}
          optionsI18nPrefix={"clothing"}
          modalComponent={
            <ReferenceModal
              title={i18n.t("clothing.pants_size.modal.title")}
              description={i18n.t("clothing.pants_size.modal.description")}
              image={pantsImg}
              alt={i18n.t("clothing.pants_size.alt")}
              testIdReference={"reference-modal-pants"}
            />
          }
          className='w-full'
        /> */}

      </div>
    </div>
  );
}
