/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import i18n from '#translate/i18n';
import SaveButton from "#components/buttons/medium/primaryButton"
import CancelButton from "#components/buttons/medium/textButton"
import DivisionLine from '#components/formComponents/DivisionLine';
import TextAreaInput from '#components/formComponents/TextAreaInput';
import DateInput from '#components/formComponents/DateInput';
import createOrUpdateYearEndBonus from '#requests/finances/allowances/createOrUpdateYearEndBonus';
import fetchAllowance from '#requests/userProfile/editions/fetchAllowance'
import addYearEndBonusSchema from '#validations/addYearEndBonusSchema';
import { yearEndBonusData as initialValues } from '#data/yearEndBonusData';
import { yupResolver } from '@hookform/resolvers/yup';
import YearEndPaymentWayInput from './YearEndPaymentWayInput'

export default function YearEndBonusDrawer({
  closeAddAllowancesDrawer,
  registeredAllowances,
  user,
  userName,
  openNewDrawer,
  setShowMessage,
  setEditObjectId,
  editObjectId,
}) {
  const formName = 'year_end_bonus_form'
  const model = 'year_end_bonus'
  const todayStart = new Date()
  todayStart.setHours(0, 0, 0, 0);
  const [chosenPayment, setChosenPayment] = useState(['']);
  const [firstPaymentPast, setFirstPaymentPast] = useState(false);
  const [submittingData, setSubmittingData] = useState(false)
  const yearEndBonusAttr = ['notes', 'first_payment_date', 'second_payment_date', 'installments_number']
  const formMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(addYearEndBonusSchema)
  });

  useEffect(() => {
    formMethods.setValue(`${model}.first_payment_date_past`, false)

    if (editObjectId) {
      fetchAllowance({ id: editObjectId, user: user, allowanceName: 'year_end_bonus' })
        .then((parsedResponse) => {
          const data = parsedResponse.data
          yearEndBonusAttr.forEach(element => {
            if (element === 'installments_number') {
              const paymentWay = Number(data[element]) === 1 ? 'one_installment' : 'two_installments'
              formMethods.setValue(`${model}.payment_way`, paymentWay)
              setChosenPayment(paymentWay)
            } else {
              formMethods.setValue(`${model}.${element}`, data[element])
            }
          });
          const pastStart = new Date(`${data['first_payment_date']}T00:00`) < todayStart
          formMethods.setValue(`${model}.first_payment_date_past`, pastStart)
          setFirstPaymentPast(pastStart)
        })
    }
  }, []);

  const submitData = (data) => {
    setSubmittingData(true)
    createOrUpdateYearEndBonus({ data: data, user: user, model: model, objectId: editObjectId })
      .then(() => {
        closeAddAllowancesDrawer()
        setShowMessage({
          success: true,
          userName: userName,
          source: editObjectId ? 'year_end_bonus_updated' : 'year_end_bonus_created'
        })
        setEditObjectId?.(null)
        openNewDrawer?.()
      }).catch(function (error) {
        setSubmittingData(false)
        throw new Error(error);
      })
  };

  function handleInstallmentChoice(event) {
    setChosenPayment(event.target.value)
  }


  return (
    <FormProvider {...formMethods}>
      <form
        id={formName}
        className='overflow-y-auto max-h-[70vh] bg-white absolute
                   sm:top-[220px] md:top-[230px] lg:top-[255px] xl:top-[275px] 2xl:top-[310px] 2k-monitor:top-[345px]
                   bottom-[100px] lg:bottom-[105px] xl:bottom-[100px]
                   right-0 left-0 mx-6 px-0.5'
        onSubmit={formMethods.handleSubmit(submitData)}
      >
        <div className={`grid gap-y-3 gap-x-4 ${chosenPayment === 'two_installments' ? 'grid-cols-1' : 'grid-cols-2'}`}>
          <YearEndPaymentWayInput
            model={model}
            name='payment_way'
            registeredAllowances={registeredAllowances}
            handleInstallmentChoice={handleInstallmentChoice}
            user={user}
            disabled={firstPaymentPast}
          />
          <div className={`${chosenPayment === 'two_installments' ? 'grid grid-cols-2 gap-y-3 gap-x-4' : 'grid gap-y-0'}`}>
            <div>
              <DateInput
                model={model}
                name='first_payment_date'
                title={chosenPayment === 'two_installments' ? i18n.t('yearEndBonus.first_payment_date') : i18n.t('yearEndBonus.payment_date')}
                classStyle='w-full'
                disabled={firstPaymentPast}
                requiredField={true}
              />
            </div>
            <div className={`${chosenPayment === 'two_installments' ? '' : 'w-0 h-0'}`}>
              <DateInput
                model={model}
                name='second_payment_date'
                title={chosenPayment === 'two_installments' ? i18n.t('yearEndBonus.second_payment_date') : ''}
                classStyle='w-full'
                shouldHide={chosenPayment === 'two_installments' ? false : true}
                requiredField={chosenPayment === 'two_installments' ? true : false}
              />
            </div>
          </div>
        </div>
        <div className='grid grid-cols-1 gap-y-3 gap-x-4 my-2'>
          <TextAreaInput
            model={model}
            name='notes'
            placeholder={i18n.t('yearEndBonus.notes_placeholder')}
            title={i18n.t('yearEndBonus.notes')}
            className='w-full'
          />
        </div>
        <div id='add_year_end_bonus_forms_buttons'
          className='fixed bottom-0 right-0 w-1/2 flex flex-row max-h-[15vh] bg-white h-[100px]'
        >
          <div className='absolute right-2.5 left-2.5 bottom-[78px]'>
            <div>
              <DivisionLine customStyle='mb-2.5' />
            </div>
            <CancelButton
              buttonId={'year-end-bonus-forms-cancel-button'}
              defaultText={i18n.t('buttons.cancel')}
              onClick={() => { closeAddAllowancesDrawer(), openNewDrawer?.() }}
              disabled={submittingData}
              imagePresent={false}
              innerDivClass={'grid'}
              type={'button'}
              buttonClass={`absolute justify-center flex w-[70px] float-left mt-2.5 ${editObjectId ? 'right-60' : 'right-[275px]'}`}
            />

            <SaveButton
              form={formName}
              defaultText={i18n.t(`${editObjectId ? 'update_allowances' : 'add_allowances'}.submit_button_text`)}
              submittingData={submittingData}
              processingText={i18n.t("buttons.saving")}
              buttonId={"year-end-bonus-forms-submit-button"}
              buttonClass={`absolute right-0 flex justify-center mr-5 flex-row mt-2.5 ${editObjectId ? 'w-44' : 'w-[214px]'}`}
              type={'submit'}
            />
          </div>
        </div>
        <input type='boolstringean' className="w-0 h-0" id={`${model}_first_payment_date_past`} {...formMethods.register(`${model}.first_payment_date_past`)} />
      </form>
    </FormProvider>
  );
}
