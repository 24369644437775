import React from "react";
import PasswordField from "./PasswordField";

const InputPassword = ({
  model,
  title,
  requiredField,
  subtitle,
  name,
  label,
  type,
  placeholder,
  maxLength,
  disabled,
  shouldSetValue,
  inviteFieldStyle,
  className,
  ...props
}) => {
  const id = model ? `${model}_${name}_input` : `${name}_input`;
  const fieldStyleClassName = "py-2 px-11 text-md block rounded mt-2 w-[102%] h-11 bg-white placeholder-placeholder-gray border border-solid border-border-gray box-border focus:placeholder-gray-400 focus:bg-white focus:border-gray-600 focus:outline-none focus:ring-2 disabled:bg-border-gray disabled:text-placeholder-gray";

  return (
    <div id={`${model}_${name}`} className="flex flex-col justify-end">
      <PasswordField
        {...props}
        inviteFieldStyle={inviteFieldStyle}
        model={model}
        label={
          <>
            { label || title }

            { requiredField && (
              <span 
                className="pl-1 text-red-500"
                id="required-field"
              >
                *
              </span>
            )}

            { subtitle && (
              <span className="pl-1">
                (<span className="font-normal">{subtitle}</span>)
              </span>
            )}
          </>
        }
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disabled}
        shouldSetValue={shouldSetValue}
        className={`${inviteFieldStyle ?? fieldStyleClassName} ${className}`}
      />
    </div>
  );
};

InputPassword.defaultProps = {
  type: "password",
  placeholder: null,
  maxLength: null,
  disabled: false,
  className: "",
};

export default InputPassword;