import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import i18n from "#translate/i18n";
import updateGuestRequest from "#requests/updateGuestRequest";
import { loginRequest } from "#requests/loginRequest";
import FirstGuestAccessSchema from "#validations/FirstGuestAccessSchema";
import { firstGuestAccessData as initialValues } from "#data/firstGuestAccessData";

import TermsOfUseModal from "../../modals/TermsOfUseModal";
import InputText from "../InputText";
import InputPassword from "../InputPassword"
import CheckboxInput from "../CheckboxInput";

import accountCircle from "#images/about-icon.svg"
import mail from "#images/profile_drawer/email-icon.svg"
import lock from "#images/lock-icon.svg"
import arrowRight from "#images/arrow-right.svg"

export default function FirstGuestAccessForm({tokenUser, tokenId}) {
  const [isLoading, setIsLoading] = useState(true);
  const MattermostRequest = false;
  const redirection = "/onboarding";

  const formMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(FirstGuestAccessSchema),
  });

  useEffect(() => {
    if(isLoading && tokenUser?.name){
      formMethods.setValue('firstGuestAccess.name', tokenUser?.name);
      formMethods.setValue('firstGuestAccess.email', tokenUser?.email);
      formMethods.setValue('firstGuestAccess.token_id', tokenId);
      setIsLoading(false)
    }
  },[isLoading, tokenId, tokenUser]);

  const inviteFieldStyle = "py-2 px-11 text-md block rounded mt-2 w-[102%] h-11 bg-white placeholder-placeholder-gray border border-solid border-border-gray box-border focus:placeholder-gray-400 focus:bg-white focus:border-gray-600 focus:outline-none focus:ring-2 disabled:bg-border-gray disabled:text-placeholder-gray"

  const onSubmit = ({ firstGuestAccess: values }) => {
    updateGuestRequest(values)
      .then((response) => {
        if(response.ok) { loginRequest(values.email, values.password, MattermostRequest, redirection ) }
      })
  }

  return (
    <div className="m-autotext-left items-center  mx-14 2xl:mx-20 mt-12 rounded-md mb-3">
      <FormProvider {...formMethods}>
        <form
          onSubmit={formMethods.handleSubmit(onSubmit)}
          className=" font-normal items-start text-black"
        >
          <div className="mt-3 w-full h-fit">
            <InputText
              model="firstGuestAccess"
              name="name"
              title={i18n.t("firstGuestAccess.name")}
              placeholder="João da Silva"
              inviteFieldStyle={inviteFieldStyle}
              icon={accountCircle}
            />
          </div>

          <div className="mt-3 w-full h-fit">
            <InputText
              model="firstGuestAccess"
              name="email"
              title={i18n.t("firstGuestAccess.email")}
              placeholder="renan@rebase.com.br"
              inviteFieldStyle={inviteFieldStyle}
              disabled={true}
              icon={mail}
            />
          </div>

          <div className="mt-3 w-full h-fit">
            <InputPassword
              type="password"
              model="firstGuestAccess"
              name="password"
              icon={lock}
              title={i18n.t("firstGuestAccess.password")}
            />
          </div>

          <div className="mt-3 w-full h-fit">
            <InputPassword
              type="password"
              model="firstGuestAccess"
              name="password_confirmation"
              icon={lock}
              title={i18n.t("firstGuestAccess.password_confirmation")}
            />
          </div>

          <div className="flex items-center mt-2.58">
            <CheckboxInput
              model="firstGuestAccess"
              name="terms_of_use"
              label="Li e concordo com os"
            />
            <TermsOfUseModal />
          </div>

          <button
            type="submit"
            id="firstGuestAccess-submit-button"
            className="flex mt-12 justify-center gap-3 font-semibold w-[102%] rounded text-lg text-white bg-rebase-blue py-3"
          >
            {i18n.t("firstGuestAccess.access")}
            <img className="mt-[1px]" src={arrowRight} alt='Arrow Right'/>
          </button>
        </form>
      </FormProvider>

    </div>
  );
}
