import React, { useState, useContext } from "react";
import UserForm from "#components/UserForm";
import createOrUpdateUserRequest from "#requests/createOrUpdateUserRequest";
import userPresentedName from '#services/userPresentedName';

import { UserContext } from "#providers/UserContextProvider"

export default function NewUser({setOpenNewUserForm, setShowMessage, openDrawers}) {
  const [creationErrors, setCreationErrors] = useState();
  const [submittingData, setSubmittingData] = useState(false);
  const { setRefreshUserData } = useContext(UserContext)

  function onSubmit(values) {
    setSubmittingData(true)
    createOrUpdateUserRequest(values, null)
      .then(() => {
        setOpenNewUserForm(false)
        setShowMessage({
          success: true,
          userName: userPresentedName(values.user),
          source: 'created_user',
        })
        openDrawers?.()
        setRefreshUserData(true)
      }).catch(function(requestReturn) {
        setSubmittingData(false)
        setCreationErrors(requestReturn.response.data.errors)
      })
  }

  return(
    <div id='new-user-form-component' className='inherit h-[calc(100vh-64px)] pt-0 flex-auto mt-0 ml-0 pl-5 bg-light-gray'>
      <UserForm
        onSubmit={onSubmit}
        requestErrors={creationErrors}
        setRequestErrors={setCreationErrors}
        closeDrawer={ () => { setOpenNewUserForm(false), openDrawers?.() } }
        context={{ register: true}}
        submittingData={submittingData}
      />
    </div>
  )
}
