import React, { useState } from "react";
import i18n from "#translate/i18n";
import imgMatterMost from "#images/mattermost.svg";

function MattermostLoginModal(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const MattermostRequest = true;
    const response = await props.targetRequest(
      email,
      password,
      MattermostRequest
    );
    setErrorMessage(response);
  };

  const openModal = () => {
    const modal = document.getElementById("mattermost-login-modal");
    modal.classList.remove("hidden");
  };

  const closeModal = () => {
    const modal = document.getElementById("mattermost-login-modal");
    modal.classList.add("hidden");
  };

  return (
    <>
      <button
        id="mattermost-button"
        className="uppercase bg-white shadow font-bold rounded text-black text-xs leading-4.1 px-2.54 py-2.54"
        onClick={openModal}
      >
        <img src={imgMatterMost} alt="Logo MatterMost" className="inline" />
        mattermost
      </button>

      <div
        id="mattermost-login-modal"
        className="fixed hidden z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4"
      >
        <div className="modal-dialog relative w-auto pointer-events-none">
          <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
            <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
              <h5
                className="text-xl font-medium leading-normal text-gray-800"
                id="mattermostModalLabel"
              >
                Entre com suas credenciais do Mattermost
              </h5>
              <img
                src={imgMatterMost}
                alt="Logo MatterMost"
                className=" mx-2 inline"
              />
              <button
                type="button"
                className="btn-close box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body relative p-4">
              <form
                onSubmit={handleSubmit}
                className="font-bold flex flex-col items-center text-black"
              >
                <div className="flex flex-col items-start">
                  <label
                    htmlFor="email"
                    className="mb-1.5 text-cyan-blue-medium-dark text-xs uppercase"
                  >
                    {i18n.t("login.email")}
                  </label>
                  <input
                    type="text"
                    id="login-form-email-modal"
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                    className={`${errorMessage && "ring-red-500 ring-2"
                      } border-none rounded shadow`}
                  />

                  <label
                    htmlFor="password"
                    className="mb-1.5 mt-2.54 text-cyan-blue-medium-dark text-xs uppercase"
                  >
                    {i18n.t("login.password")}
                  </label>
                  <input
                    type="password"
                    id="login-form-password-modal"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    className={`${errorMessage && "ring-red-500 ring-2"
                      } border-none rounded shadow`}
                  />

                  <span
                    name="validationError"
                    className={`items-center font-medium tracking-wide text-red-500 text-xs mt-1`}
                  >
                    {errorMessage && i18n.t(`errors.${errorMessage}`)}
                  </span>
                </div>
              </form>
            </div>
            <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
              <button
                type="button"
                className="px-6 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out"
                onClick={closeModal}
              >
                Cancelar
              </button>
              <button
                type="submit"
                onClick={handleSubmit}
                className="px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
              >
                {i18n.t("login.access")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MattermostLoginModal;
