import React from 'react';

export default function TotalToReceive({ row, value }) {
  const allData = row.original ? row.original : {}
  const id = allData.id
  function valueToCurrency(value) {
    const newValue = value ? +value : 0
    return newValue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  }
  return (
    <div className='flex items-center'>
      <div className='flex-shrink-0 w-full'>
        <div id={`report_total_to_receive_user_${id}`} className='text-center h-5 font-sans not-italic font-bold text-sm text-cyan-blue-medium-dark'>{valueToCurrency(value)}</div>
      </div>
    </div>
  );
}
