import React, { useState, useRef, useEffect } from "react";
import headerModal from "#images/modal-register-yellow.svg"
import CloseButton from '#images/add_allowances/close_icon.svg';
import i18n from "#translate/i18n"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { inviteUserFields as initialValues } from "#data/inviteUserData"
import inviteUserSchema from "#validations/inviteUserSchema"
import InviteUserRequest from "#requests/InviteUserRequest"
import { FormInviteUser } from "#components"
import ErrorMessage from '#components/messages/ErrorMessage'
import useOnClickOutside from '#services/useOnClickOutside';

export default function InviteUser({ setShowMessageInviteSuccess, setShowInviteModal }) {
  const [creationErrors, setCreationErrors] = useState()

  const submitData = (values) => {
    inviteUser(values)
      .then(function () {
        setShowMessageInviteSuccess(true)
        setShowInviteModal(false)
      })
      .catch(function(requestReturn) {
        setCreationErrors(requestReturn.response.data.errors)
      })
  }

  const inviteUser = async (params) => {
    await InviteUserRequest(params)
  }

  const formMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(inviteUserSchema),
  })

  const inviteModal = useRef();
  useOnClickOutside({ ref: inviteModal, handler: () => { setShowInviteModal(false) } });

  useEffect(() => {
    const closeButton = document.getElementById('close_invite_modal_button')
    closeButton.focus();
    closeButton.blur();
  }, [])

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        document.getElementById('invite-save-button').click();
      }
    };

    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 z-[1400] h-screen bg-black-opacity-0.8">
      <div
        role="main"
        id='main-invite-modal'
        className="z-[1500] fixed top-0 flex justify-center items-center w-screen h-screen overflow-y-auto"
      >
        <div
          role="figure"
          ref={inviteModal}
          className="rounded-2.5xl shadow-lg max-w-[770px] bg-white mb-[150px] mt-[400px]
                     sm:mt-[67%] md:mt-[61%] lg:mt-[46%] xl:mt-[35%] 2xl:mt-[17%]
                     sm:mb-[10%] md:mb-[10%] lg:mb-[10%] xl:mb-[10%] 2xl:mb-[11%]"
        >
          <div className="relative" >
            <button
              id='close_invite_modal_button'
              type='button'
              className='absolute top-2.5 right-2.5'
              onClick={() => setShowInviteModal(false) }
            >
              <img
                id='close_invite_modal_image'
                src={CloseButton}
                alt={i18n.t('buttons.logout')}
              />
            </button>
            <img src={headerModal} className="rounded-t-2.5xl w-full" alt="" id='invite-modal-header-image' />
          </div>
          <div role="caption" className="pt-6 pb-10 px-6">
            <h1 className="font-semibold text-xl leading-6 text-center text-black" id='invite-modal-title'>
              {i18n.t("modal_user_register.new_rebaser")}
            </h1>
            <p className="font-normal text-sm leading-6 text-center text-cyan-blue-medium-dark">
              {i18n.t("modal_user_register.text")}
            </p>
          </div>

          <FormProvider {...formMethods}>
            <form
              onSubmit={formMethods.handleSubmit(submitData)}
              id="inviteUserRequest"
              className="px-6 pb-11"
            >
              <div className="grid grid-cols-1">
                <div className="space-y-2.5 -mt-8 "
                >
                  {creationErrors && (
                    <ErrorMessage
                      errors={creationErrors}
                      removeErrors={setCreationErrors}
                      model={'invite'}
                      userName={''}
                    />
                  )}
                  <FormInviteUser />
                </div>

                <div className="h-px w-full mt-8 mb-6 bg-border-gray" />

                <div className="flex justify-center">
                  <button
                    data-testid='invite-cancel-button'
                    onClick={() => setShowInviteModal(false)}
                    className="rounded mr-[30px] text-rebase-blue py-2.5 px-4 border-rebase-blue border-[1px] font-semibold"
                  >
                    {i18n.t("modal_user_register.cancel")}
                  </button>

                  <button
                    data-testid='invite-save-button'
                    id='invite-save-button'
                    type="submit"
                    form="inviteUserRequest"
                    className="rounded text-white py-2.5 px-4 bg-rebase-blue border-rebase-blue border-[1px] font-semibold"
                  >
                    {i18n.t("modal_user_register.add_new_rebaser")}
                  </button>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  )
}
