import React from "react";
import RoundPicture from "#components/user/RoundPicture";
import { TooltipMessage } from "#components";
import NoAvatarIcon from "#images/no-avatar.svg"

export default function SelectedUsersPictures({selectedUsers}) {

  const showMoreThanFiveUsers = (UsersArray) => {
    return(
      <pre className="w-max py-1 px-3 text-white leading-6 rounded-lg bg-border-blue tooltip-position font-normal text-xs font-sans not-italic">
        {UsersArray.map((person) => `${person.full_name}\n`)}
      </pre>
    )
  }
  return(
    <div className="contents">
      {
        selectedUsers.map((person, index) => {
          if (index <= 4){
            return (
              <div key={`customized_data_picture_${person.id}`} className={'relative'} >
                <TooltipMessage
                  arrowClass="w-2.5 h-2.5 bg-border-blue arrow-position before:bg-border-blue before:visible invisible before:absolute before:rotate-45 before:transform-y-[10px] before:w-2.5 before:h-2.5 "
                  tooltipClass="w-max py-1 px-3 text-white leading-6 rounded-lg bg-border-blue tooltip-position font-normal text-xs font-sans not-italic"
                  placement='bottom'
                  offset={[0, 10]}
                  message={person.full_name}
                  buttonId='export-customized-data-face-picture'
                  showMessage={selectedUsers.length <= 5 ? true : false}
                  imageComponent={
                    <>
                      <RoundPicture
                        divStyle={`w-11 h-11 ${index > 0 && '-ml-4'}`}
                        imgStyle="border-2 border-white"
                        src={person.face_picture?.url || NoAvatarIcon}
                        alt={`${person.face_picture?.url || 'Avatar'}`}
                        id={`picture-${index}`}
                      />
                    </>
                  }
                />
              </div>
            )
          }
        })
      }
      {
        selectedUsers.length > 5 && (
          <TooltipMessage
            arrowClass="w-2.5 h-2.5 bg-border-blue arrow-position before:bg-border-blue before:visible invisible before:absolute before:rotate-45 before:transform-y-[10px] before:w-2.5 before:h-2.5 "
            tooltipClass="w-max py-1 px-3 text-white leading-6 rounded-lg bg-border-blue tooltip-position font-normal text-xs font-sans not-italic"
            placement='bottom'
            offset={[0, 10]}
            message={showMoreThanFiveUsers(selectedUsers)}
            customPopperStyle={{zIndex: 500}}
            buttonId='export-customized-data-face-picture'
            imageComponent={
              <>
                <div
                  className="w-11 h-11 -ml-4 bg-light-gray border-cyan-blue-medium-dark border-2 rounded-3xl flex items-center justify-center font-inter font-semibold font-sans text-cyan-blue-medium-dark relative"
                  id="more-than-five-pictures"
                >
                  {`+${selectedUsers.length-5}`}
                </div>
              </>
            }
          />
  )
      }
    </div>
  )
}