const translations = {
  removedMessage: 'Este item não pode ser removido ou editado, pois já foi removido anteriormente.',
  newUserCreatedByAdmin: {
    title: 'Cadastro de Perfil',
    description: '{{admin}} cadastrou o perfil de {{person}} no Rebase Pessoas.',
  },
  userDismissalCreated: {
    title: 'Desligamento',
    description: '{{admin}} registrou o desligamento de <strong>{{person}}</strong> em <strong>{{createdDay}}/{{createdMonth}}/{{createdYear}}</strong>.',
    editDismissal: 'Editar registro de desligamento',
    dismissal_reason: 'Motivo do desligamento: ',
    exit_date: 'Data de saída da Rebase: ',
    exit_meeting_date: "Data da reunião de desligamento: ",
    invoice_installments_value: 'Valor da Nota Fiscal: ',
    invoice_installments_number_title: 'Forma de pagamento: ',
    invoice_installments_number_one: "Pagamento integral",
    invoice_installments_number_other: "{{count}} parcelas",
    extended_allowance: {
      title: 'Benefício estendido {{count}}: ',
      name: {
        'Meal': 'Auxílio Alimentação',
        'HomeOffice': 'Auxílio Home Office',
        'PsychologicalSupport': 'Suporte Psicológico',
        'Healthcare': 'Prêmio Saúde',
      },
    },
    general_notes: 'Anotações gerais: ',
    edit_dismissal: 'Editar registro de desligamento',
    reason: {
      title: "Motivo",
      dismissal_collaborator: "Desligamento pelo colaborador",
      dismissal_rebase: "Desligamento pela Rebase",
      others: "Outros"
    },
    no_comments: 'Nenhuma observação foi adicionada.',
  },
  userDismissalUpdated: {
    title: 'Atualização de registro de desligamento',
    description: "{{admin}} atualizou o <strong>Registro de desligamento</strong> de <strong>{{person}}</strong>."
  },
  salaryUpdateRequestCreated: {
    title: 'Reajuste de remuneração',
    description: "{{admin}} adicionou um reajuste de <strong>{{readjustmentValue}}</strong> válido a partir de <strong>{{validDay}}/{{validMonth}}/{{validYear}}</strong>. ",
    newSalaryValue: 'Valor da nova remuneração: ',
    percentageReadjustment: 'Percentual de reajuste: ',
    observations: 'Observações: ',
    editReadjustment: 'Editar reajuste',
    removeReadjustment: 'Remover reajuste',
    missingNotes: 'Nenhuma observação foi adicionada.',
    confirmation: {
      title: 'Remover reajuste de remuneração',
      text: 'Ao remover o reajuste de remuneração, o valor correspondente deixará de ser creditado para '
    },
    removalSuccess: {
      title: 'Reajuste de remuneração removido',
      text: 'O <strong>Reajuste de remuneração</strong> foi removido com sucesso.'
    }
  },
  salaryUpdateRequestUpdated: {
    title: 'Atualização de reajuste de remuneração',
    description: "{{admin}} atualizou o <strong>Reajuste de remuneração</strong>. Agora o valor será de <strong>{{readjustmentValue}}</strong>, válido a partir de <strong>{{validDay}}/{{validMonth}}/{{validYear}}</strong>.",
  },
  salaryUpdateRequestRemoved: {
    title: 'Remoção de reajuste de remuneração',
    description: "{{admin}} <strong>removeu</strong> o <strong>Reajuste de remuneração</strong> de <strong>{{readjustmentValue}}</strong>, e este valor não será mais creditado. ",
  },
  salaryUpdateRequestComplete: {
    title: 'Pagamento reajuste de remuneração',
    description: "Foi creditado um reajuste de remuneração para {{userName}}. A remuneração passou de <strong>{{previousValue}}</strong> para <strong>{{newValue}}</strong> a partir de hoje. "
  },
  userRegisterUpdated: {
    title: 'Atualização de cadastro',
    externalAgent: { description: "{{admin}} atualizou o cadastro de {{userName}} no Rebase Pessoas.", },
    ownAgent: { description: "{{userName}} atualizou seu cadastro no Rebase Pessoas.", },
  },
  expenseAssistanceCreated: {
    title: 'Ajuda de custo',
    description: "{{admin}} adicionou uma ajuda de custo <strong>{{frequency}}</strong> no valor de <strong>{{monthlyValue}}</strong>, a ser paga a partir de <strong>{{paymentDay}}/{{paymentMonth}}/{{paymentYear}}</strong>. ",
    category: 'Categoria: ',
    reason: 'Motivo: ',
    notes: 'Observações:',
    duration: {
      title: 'Duração: ',
      months: ' meses',
      undetermined_time: 'Tempo indeterminado'
    },
    payment_type: {
      title: 'Forma de pagamento: ',
      benefits_card: "Cartão de benefícios",
      invoice: "Nota fiscal",
    },
    frequency: {
      single: "pontual",
      recurring: "recorrente",
    },
    missingNotes: 'Nenhuma observação foi adicionada.',
    editAssistance: 'Editar ajuda de custo',
    removeAssistance: 'Remover ajuda de custo',
    confirmation: {
      title: 'Remover prêmio',
      text: 'Ao remover a ajuda de custo, o valor correspondente deixará de ser creditado para '
    },
    removalSuccess: {
      title: 'Ajuda de custo removida',
      text: "A ajuda de custo foi removida com sucesso."
    },
    removed: 'Este item não pode ser removido ou editado, pois já foi removido anteriormente.',
  },
  expenseAssistanceRemoved: {
    title: 'Remoção de ajuda de custo',
    description: '{{admin}} <strong>removeu</strong> a <strong>Ajuda de custo</strong> de <strong>{{value}}</strong>, e este valor não será mais creditado.',
  },
  expenseAssistanceUpdated: {
    title: 'Atualização de ajuda de custo',
    description: '{{admin}} <strong>atualizou</strong> a <strong>Ajuda de custo</strong>. Agora o valor de <strong>{{new_value}}</strong> será pago de forma <strong>{{frequency}}</strong> a partir de <strong>{{paymentDay}}/{{paymentMonth}}/{{paymentYear}}</strong>.',
    frequency: {
      single: "pontual",
      recurring: "recorrente",
    },
    duration: {
      title: 'Duração: ',
      months: ' meses',
      undetermined_time: 'Tempo indeterminado'
    },
  },
  parentalLeaveCreated: {
    title: 'Presença Parental',
    description: "{{admin}} agendou a presença parental de {{userName}} para <strong>{{startDay}}/{{startMonth}}/{{startYear}}</strong> com retorno previsto para <strong>{{returnDay}}/{{returnMonth}}/{{returnYear}}</strong>. ",
    observations: 'Observações: ',
    missingNotes: 'Nenhuma observação foi adicionada',
    editParentalLeave: 'Editar benefício',
    removeParentalLeave: 'Remover benefício',
    confirmation: {
      title: 'Remover benefício',
      text: 'Ao remover o benefício, ele deixará de ser creditado para '
    },
    removalSuccess: {
      title: 'Benefício removido',
      text: "A <strong>{{ name }}</strong> foi removida com sucesso."
    },
  },
  parentalLeaveUpdated: {
    title: 'Atualização presença parental',
    description: "{{admin}} <strong>atualizou</strong> a <strong>Presença Parental</strong> agora o período será de <strong>{{startDay}}/{{startMonth}}/{{startYear}}</strong> com retorno previsto para <strong>{{returnDay}}/{{returnMonth}}/{{returnYear}}</strong>. ",
    observations: 'Observações: ',
    missingNotes: 'Nenhuma observação foi adicionada',
    editParentalLeave: 'Editar benefício',
    removeParentalLeave: 'Remover benefício',
  },
  parentalLeaveRemoved: {
    title: 'Remoção presença parental',
    description: "{{admin}} <strong>removeu</strong> a <strong>Presença Parental</strong> agora o período não será mais contabilizado.",
  },
  parentalLeaveStart: {
    title: 'Início da Presença parental',
    description: "{{userName}} entrou em período de presença parental com retorno previsto para {{returnDay}}/{{returnMonth}}/{{returnYear}}. "
  },
  parentalLeaveReturn: {
    title: 'Retorno da Presença parental',
    description: "{{userName}} retornou do período de presença parental. "
  },
  bonusRewardCreated: {
    title: 'Prêmio',
    quintalk: 'Prêmio Quintalk',
    live_coding: 'Prêmio Live Coding',
    competitions: 'Prêmio Concursos',
    party_kit: 'Kit Festa',
    onboarding: 'Auxílio onboarding',
    description: '{{admin}} adicionou um prêmio de <strong>{{payment}}</strong>, a ser pago em <strong>{{day}}/{{month}}/{{year}}</strong>. ',
    extraInformation: 'Ver detalhes.',
    missing_notes: 'Nenhuma observação adicionada.',
    observations: 'Observações: ',
    payment_type: {
      title: 'Forma de pagamento: ',
      benefits_card: 'Cartão de benefícios',
      invoice: 'Nota fiscal',
    },
    editReward: 'Editar prêmio',
    removeReward: 'Remover prêmio',
    confirmation: {
      title: 'Remover prêmio',
      text: 'Ao remover o prêmio, o valor correspondente deixará de ser creditado para '
    },
    removalSuccess: {
      title: 'Prêmio removido',
      text: "O <strong>{{ name }}</strong> foi removido com sucesso."
    },
    removed: 'Este item não pode ser removido ou editado, pois já foi removido anteriormente.',
  },
  bonusRewardRemoved: {
    title: 'Remoção de prêmio',
    quintalk: 'Prêmio Quintalk',
    live_coding: 'Prêmio Live Coding',
    competitions: 'Prêmio Concursos',
    party_kit: 'Prêmio Kit Festa',
    onboarding: 'Auxílio Onboarding',
    description: '{{admin}} <strong>removeu</strong> o <strong>{{rewardType}}</strong>, e o valor correspondente não será mais creditado. ',
  },
  bonusRewardUpdated: {
    title: 'Atualização de prêmio',
    descriptionWithTypeChange: "{{admin}} atualizou o <strong>{{ previousRewardType }}</strong> para o <strong>{{ currentRewardType }}</strong>. Agora o valor será de <strong>{{bonusValue}}</strong> com pagamento previsto para <strong>{{paymentDay}}/{{paymentMonth}}/{{paymentYear}}</strong>.",
    descriptionWithoutTypeChange: "{{admin}} atualizou o <strong>{{ currentRewardType }}</strong>. Agora o valor será de <strong>{{bonusValue}}</strong> com pagamento previsto para <strong>{{paymentDay}}/{{paymentMonth}}/{{paymentYear}}</strong>.",
    rewardType: {
      quintalk: 'Prêmio Quintalk',
      live_coding: 'Prêmio Live Coding',
      competitions: 'Prêmio Concursos',
      party_kit: 'Kit Festa',
      onboarding: 'Auxílio onboarding',
    },
    paymentType: {
      title: 'Forma de pagamento: ',
      benefits_card: 'Cartão de benefícios',
      invoice: 'Nota fiscal',
    },
    observations: 'Observações: ',
    missingNotes: 'Nenhuma observação adicionada.',
  },
  vacationCreated: {
    title: 'Descanso remunerado',
    description: "{{admin}} agendou o descanso remunerado de {{userName}} para {{startDay}}/{{startMonth}}/{{startYear}} com retorno previsto para {{returnDay}}/{{returnMonth}}/{{returnYear}}. ",
      observations: 'Observações: ',
      missing_notes: 'Nenhuma observação adicionada.',
      reference_year: 'Ano de referência: ',
      total_business_days: {
        title: 'Total de dias úteis solicitados: ',
        max_days_with_bonus_one: 'dias + 1 dia de bônus',
        max_days_with_bonus_other: 'dias + {{count}} dias de bônus',
        days_one: '1 dia',
        days_other: '{{count}} dias',
      },
    bonus_added: {
      title: 'Com pagamento de bônus: ',
      true: 'Sim',
      false: 'Não',
    },
    bonus_percentage: {
      title: 'Percentual do bônus em relação à remuneração: '
    },
    bonus_value: {
      title: 'Valor do bônus desc. remunerado: ',
    },
    editVacation: 'Editar benefício',
    removeVacation: 'Remover benefício',
    extraInformation: 'Ver detalhes.',
    confirmation: {
      title: 'Remover benefício',
      text: 'Ao remover o benefício, ele deixará de ser creditado para '
    },
    removalSuccess: {
      title: 'Benefício removido',
      text: "O <strong>{{ name }}</strong> foi removido com sucesso."
    },
  },

  vacationUpdated: {
    title: 'Atualização do descanso remunerado',
    description: "{{admin}} <strong>atualizou</strong> o <strong>Descanso Remunerado</strong> agora o período será de {{startDay}}/{{startMonth}}/{{startYear}} com retorno previsto para {{returnDay}}/{{returnMonth}}/{{returnYear}}. ",
    removeVacation: 'Remover benefício',
    confirmation: {
      title: 'Remover benefício',
      text: 'Ao remover o benefício, ele deixará de ser creditado para '
    },
  },
  vacationRemoved: {
    title: 'Remoção descanso remunerado',
    description: "{{admin}} <strong>removeu</strong> o <strong>Descanso Remunerado</strong> o período de descanso não será mais contabilizado.",
  },
  vacationStart: {
    title: 'Início do descanso remunerado',
    description: "{{userName}} entrou em período de descanso remunerado com retorno previsto para {{returnDay}}/{{returnMonth}}/{{returnYear}}. "
  },
  vacationReturn: {
    title: 'Retorno do Descanso remunerado',
    description: "{{userName}} retornou do período de descanso remunerado. "
  },
  vacationCreditPublished: {
    title: 'Pagamento do descanso remunerado',
    description: "Foi creditado um bônus de descanso remunerado para {{userName}} no valor de <strong>{{currentValue}}</strong>. ",
  },
  homeOfficeCreated: {
    title: 'Auxílio Home Office',
    description: "{{admin}} adicionou o Auxílio Home Office para {{userName}} no valor mensal de <strong>{{homeOfficeValue}}</strong>.",
    observations: 'Observações: ',
    editAssistance: 'Editar benefício',
    removeAssistance: 'Remover benefício',
    missingNotes: 'Nenhuma observação adicionada.',
    confirmation: {
      title: 'Remover benefício',
      text: 'Ao remover o benefício, ele deixará de ser creditado para '
    },
    removalSuccess: {
      title: 'Benefício removido',
      text: "O <strong>{{ name }}</strong> foi removido com sucesso."
    },
    removed: 'Este item não pode ser removido ou editado, pois já foi removido anteriormente.',
  },
  homeOfficeUpdated: {
    title: 'Atualização do auxílio home office',
    description: "{{admin}} <strong>atualizou</strong> o <strong>Auxílio Home Office</strong> de <strong>{{homeOfficeValue}}</strong>.",
    observations: 'Observações: ',
    missingNotes: 'Nenhuma observação adicionada.',
    editAssistance: 'Editar benefício',
    removeAssistance: 'Remover benefício',
    confirmation: {
      title: 'Remover benefício',
      text: 'Ao remover o benefício, ele deixará de ser creditado para '
    },
    removalSuccess: {
      title: 'Benefício removido',
      text: "O <strong>{{ name }}</strong> foi removido com sucesso."
    },
  },
  homeOfficeRemoved: {
    title: 'Remoção do auxílio home office',
    description: "{{admin}} <strong>removeu</strong> o <strong>Auxílio Home Office</strong>, o valor de <strong>{{homeOfficeValue}}</strong> não será mais creditado.",
  },
  mealCreated: {
    title: 'Auxílio Alimentação',
    description: "{{admin}} adicionou o <strong>Auxílio Alimentação</strong> para {{userName}} no valor mensal de <strong>{{mealValue}}</strong>. ",
    missing_notes: 'Nenhuma observação adicionada',
    observations: 'Observações: ',
    editAllowance: 'Editar benefício',
    removeAllowance: 'Remover benefício',
    confirmation: {
      title: 'Remover benefício',
      text: 'Ao remover o benefício, ele deixará de ser creditado para '
    },
    removalSuccess: {
      title: 'Benefício removido',
      text: "O <strong>{{ name }}</strong> foi removido com sucesso."
    },
    removed: 'Este item não pode ser removido ou editado, pois já foi removido anteriormente.',
  },
  mealUpdated: {
    title: 'Atualização do auxílio alimentação',
    description: "{{admin}} <strong>atualizou</strong> o <strong>Auxílio Alimentação</strong> de <strong>{{mealValue}}</strong>. ",
    missing_notes: 'Nenhuma observação adicionada',
    observations: 'Observações: ',
    editAllowance: 'Editar benefício',
    removeAllowance: 'Remover benefício',
    removed: 'Este item não pode ser removido ou editado, pois já foi removido anteriormente.',
  },
  mealRemoved: {
    title: 'Remoção auxílio alimentação',
    description: "{{admin}} <strong>removeu</strong> o Auxílio Alimentação, o valor de <strong>{{mealValue}}</strong> não será mais creditado.",
  },
  psychologicalAssistanceCreated: {
    title: 'Auxílio Psicológico',
    descriptionTotal: "{{admin}} adicionou o Auxílio psicológico para {{userName}} com <strong>{{count}} sessão mensal</strong>. ",
    descriptionTotalPlural: "{{admin}} adicionou o Auxílio psicológico para {{userName}} com <strong>{{count}} sessões mensais</strong>.",
    missingNotes: 'Nenhuma observação foi adicionada.',
    observations: 'Observações: ',
    editBenefit: 'Editar benefício',
    removeBenefit: 'Remover benefício',
    confirmation: {
      title: 'Remover benefício',
      text: 'Ao remover o benefício, ele deixará de ser creditado para '
    },
    removalSuccess: {
      title: 'Benefício removido',
      text: "O <strong>{{ name }}</strong> foi removido com sucesso."
    },
    removed: 'Este item não pode ser removido ou editado, pois já foi removido anteriormente.',
  },
  psychologicalAssistanceUpdated: {
    title: 'Auxílio Psicológico',
    descriptionTotal: "{{admin}} <strong>atualizou</strong> o <strong>Auxílio psicológico</strong>, agora será <strong>{{count}} sessão mensal</strong>. ",
    descriptionTotalPlural: "{{admin}} <strong>atualizou</strong> o <strong>Auxílio psicológico</strong>, agora serão <strong>{{count}} sessões mensais</strong>. ",
    missingNotes: 'Nenhuma observação foi adicionada.',
    observations: 'Observações: ',
    editBenefit: 'Editar benefício',
    removeBenefit: 'Remover benefício',
    removed: 'Este item não pode ser removido ou editado, pois já foi removido anteriormente.',
  },
  psychologicalAssistanceRemoved: {
    title: 'Remoção auxílio psicológico',
    description: "{{admin}} <strong>removeu</strong> o <strong>Auxílio psicológico</strong> e as sessões mensais não serão mais contabilizadas.",
  },
  yearEndBonusCreated: {
    title: 'Bônus de fim de ano',
    one: { description: '{{admin}} adicionou o Bônus de fim de ano para {{person}} com pagamento em <strong>{{installments}}x</strong> e data prevista para pagamento em <strong>{{day}}/{{month}}/{{year}}</strong>. ' },
    two: { description: '{{admin}} adicionou o Bônus de fim de ano para {{person}} com pagamento em <strong>{{installments}}x</strong> e data prevista para pagamento em <strong>{{day}}/{{month}}/{{year}}</strong> e <strong>{{day_end}}/{{month_end}}/{{year_end}}</strong>. ' },
    notes: 'Observações:',
    missingNotes: 'Nenhuma observação foi adicionada.',
    editYearEndBonus: 'Editar benefício',
    removeYearEndBonus: 'Remover benefício',
    confirmation: {
      title: 'Remover benefício',
      text: 'Ao remover o benefício, ele deixará de ser creditado para '
    },
    removalSuccess: {
      title: 'Benefício removido',
      text: "O <strong>{{ name }}</strong> foi removido com sucesso."
    },
    removed: 'Este item não pode ser removido ou editado, pois já foi removido anteriormente.'
  },
  yearEndBonusUpdated: {
    title: 'Atualização bônus de fim de ano',
    one: { description: '{{admin}} <strong>atualizou</strong> o <strong>Bônus de fim de ano</strong>. Agora o pagamento será em <strong>{{installments}}x</strong> e data prevista para pagamento em <strong>{{day}}/{{month}}/{{year}}</strong>. ' },
    two: { description: '{{admin}} <strong>atualizou</strong> o <strong>Bônus de fim de ano</strong>. Agora o pagamento será em <strong>{{installments}}x</strong> com data prevista para pagamento em <strong>{{day}}/{{month}}/{{year}}</strong> e <strong>{{day_end}}/{{month_end}}/{{year_end}}</strong>. ' },
    notes: 'Observações:',
    missingNotes: 'Nenhuma observação foi adicionada.',
    editYearEndBonus: 'Editar benefício',
    removeYearEndBonus: 'Remover benefício',
    removed: 'Este item não pode ser removido ou editado, pois já foi removido anteriormente.'
  },
  yearEndBonusRemoved: {
    title: 'Remoção do bônus de fim de ano',
    description: "{{admin}} <strong>removeu</strong> o <strong>Bônus de fim de ano</strong>, o valor não será mais creditado.",
  },
  healthcareCreated: {
    title: 'Prêmio Saúde',
    no_descendants: { description: "{{admin}} adicionou o Prêmio Saúde para {{userName}} no valor mensal de <strong>{{currentValue}}</strong>. ", },
    one_descendant: { description: "{{admin}} adicionou o Prêmio Saúde para {{userName}} e mais {{descendantsCount}} pessoa beneficiária no valor mensal de <strong>{{currentValue}}</strong>. ", },
    multiple_descendants: { description: "{{admin}} adicionou o Prêmio Saúde para {{userName}} e mais {{descendantsCount}} pessoas beneficiárias no valor mensal de <strong>{{currentValue}}</strong>." },
    beneficiary: 'Beneficiário',
    value: 'Valor',
    does_not_have_dependents: 'Não possui dependentes.',
    editAllowance: 'Editar benefício',
    removeAllowance: 'Remover benefício',
    documentPresent: 'Há comprovante. Verifique na aba Documentos.',
    document: 'Comprovante: ',
    confirmation: {
      title: 'Remover benefício',
      text: 'Ao remover o benefício, ele deixará de ser creditado para '
    },
    removalSuccess: {
      title: 'Benefício removido',
      text: "O <strong>{{ name }}</strong> foi removido com sucesso."
    },
  },
  healthcareUpdated: {
    title: 'Atualização do prêmio saúde',
    description: '{{admin}} <strong>atualizou</strong> o <strong>Prêmio Saúde</strong>. Agora o valor mensal será de <strong>{{currentValue}}</strong>.',
    documentPresent: 'Há comprovante. Verifique na aba Documentos.',
    document: 'Comprovante: ',
    beneficiary: 'Beneficiário',
    value: 'Valor',
    does_not_have_dependents: 'Não possui dependentes.',
    editAllowance: 'Editar benefício',
    removeAllowance: 'Remover benefício',
  },
  healthcareRemoved: {
    title: 'Remoção do prêmio saúde',
    description: '{{admin}} <strong>removeu</strong> o <strong>Prêmio Saúde</strong>, o valor de <strong>{{currentValue}}</strong> não será mais creditado.',
  },
  healthcareCreditPublished: {
    title: 'Pagamento do prêmio saúde',
    no_descendants: { description: "Foi creditado um Prêmio Saúde para {{userName}} no valor mensal de <strong>{{currentValue}}</strong>. ", },
    one_descendant: { description: "Foi creditado um Prêmio Saúde para {{userName}} e mais {{descendantsCount}} pessoa beneficiária no valor mensal de <strong>{{currentValue}}</strong>." },
    multiple_descendants: { description: "Foi creditado um Prêmio Saúde para {{userName}} e mais {{descendantsCount}} pessoas beneficiárias no valor mensal de <strong>{{currentValue}}</strong>." },
  },
  startedInCompany: {
    title: 'Início na empresa',
    description: "{{userName}} iniciou na {{descriptionCompany}}.",
    companies: {
      REBASE: 'Rebase',
      CAMPUSCODE: 'Campus Code',
      BRAINN: 'Brainn',
    },
  },
  workLeaveCreated: {
    title: 'Período de afastamento',
    description: "{{admin}} agendou o <strong>período de afastamento</strong> para {{person}} com início em <strong>{{startDay}}/{{startMonth}}/{{startYear}}</strong> e retorno previsto para <strong>{{returnDay}}/{{returnMonth}}/{{returnYear}}</strong>. ",
    reason: "Motivo: ",
    observations: 'Observações: ',
    editWorkLeave: 'Editar afastamento',
    removeWorkLeave: 'Remover afastamento',
    missingNotes: 'Nenhuma observação foi adicionada.',
    document: 'Comprovante: ',
    documentPresent: 'Há comprovante. Verifique na aba Documentos.',
    documentMissing: 'Não há comprovante.',
    confirmation: {
      title: 'Remover período de afastamento',
      text: 'Ao remover o período de afastamento, os dias de ausência deixarão de ser contabilizados para '
    },
    removalSuccess: {
      title: 'Período de afastemento removido',
      text: "O <strong>período de afastamento</strong> foi removido com sucesso."
    },
  },
  workLeaveUpdated: {
    title: 'Atualização período de afastamento',
    description: "{{admin}} <strong>atualizou</strong> o <strong>Período de afastamento</strong>, agora o período será de <strong>{{startDay}}/{{startMonth}}/{{startYear}}</strong> com retorno previsto para <strong>{{returnDay}}/{{returnMonth}}/{{returnYear}}</strong>. ",
    reason: "Motivo: ",
    observations: 'Observações: ',
    editWorkLeave: 'Editar afastamento',
    removeWorkLeave: 'Remover afastamento',
    missingNotes: 'Nenhuma observação foi adicionada.',
    document: 'Comprovante: ',
    documentPresent: 'Há comprovante. Verifique na aba Documentos.',
    documentMissing: 'Não há comprovante.',
    confirmation: {
      title: 'Remover período de afastamento',
      text: 'Ao remover o período de afastamento, os dias de ausência deixarão de ser contabilizados para '
    },
    removalSuccess: {
      title: 'Período de afastemento removido',
      text: "O <strong>período de afastamento</strong> foi removido com sucesso."
    },
  },
  workLeaveRemoved: {
    title: 'Remoção período de afastamento',
    description: '{{admin}} <strong>removeu</strong> o <strong>Período de afastamento</strong> e ele não será mais contabilizado.',
  },
  workLeaveStart: {
    title: 'Início do período de afastamento',
    description: "{{userName}} entrou em período de afastamento com retorno previsto para <strong>{{returnDay}}/{{returnMonth}}/{{returnYear}}</strong>. "
  },
  workLeaveReturn: {
    title: 'Retorno do período de afastamento',
    description: "{{userName}} retornou do período de afastamento. "
  },
}

export { translations }
