/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react"
// import SearchLens from '#images/search-lens.svg'
// import BarTop from '#images/bar-top.svg';
import Hand from '#images/hand-top.svg';
// import Bell from '#images/bell-top.svg';
import ProfileAvatar from '#images/profile-avatar.svg'
import HeaderMenu from "#components/navigation/HeaderMenu";
import LoadingPage from "#components/navigation/LoadingPage";
import SuccessMessage from "#components/finances/messages/SuccessMessage"
import RoundPicture from "#components/user/RoundPicture";

import userRequest from "#requests/UserRequest";
import userSessionRequest from "#requests/userSessionRequest";
import leaveSchedules from "#requests/users/leaveSchedules"
import userPresentedName from '#services/userPresentedName';

import ProfileDrawer from '#components/user/profile/ProfileDrawer'
import FinancialAdditions from "../finances/drawers/FinancialAdditions"

import NewUser from "#components/NewUser";
import EditUser from "#components/EditUser"

import { UserContext } from "#providers/UserContextProvider"
import { UserFormsContext } from "#providers/UserFormsProvider"

export default function Header() {
  const [authenticatedUserData, setAuthenticatedUserData] = useState("");
  const [sessionData, setSesisonData] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const {
    setRefreshUserData,
    selectedPerson,
    openedDrawers,
    handleDrawersOpen,
    menuOrigin,
    setProfileTabName,
    refreshUserData,
    setUsersLeaveSchedules,
    showMessage,
    setShowMessage
  } = useContext(UserContext)
  const {
    openUserEditForms,
    setOpenUserEditForms,
    userDataEditForms,
    setUserDataEditForms,
    openNewUserForm,
    setOpenNewUserForm,
  } = useContext(UserFormsContext)

  useEffect(() => {
    async function fetchSessionData() {
      const userSession = await userSessionRequest();
      setSesisonData(userSession)

      const userData = await userRequest(userSession.id);
      setAuthenticatedUserData(userData);
      setIsLoading(false);
    }
    fetchSessionData()
  }, []);

  useEffect(() => {
    if(refreshUserData && sessionData?.role === 'admin') {
      (async () => {
        const leavesSchedule = await leaveSchedules();
        setUsersLeaveSchedules(leavesSchedule)
      })();
    }
  }, [refreshUserData, sessionData]);

  if (isLoading) {
    return <div className="loading"><LoadingPage /></div>;
  }

  return (
    <>
      <div className="flex h-16 justify-end bg-white relative">
        <div className="flex items-center mr-[30px]">
          {/* <img className="w-5 h-5" src={SearchLens} alt="search icon" />
          <img className="h-[42px] ml-5" src={BarTop} alt="bar icon" />
          <img className="w-[16.65px] h-[22.65px] ml-5" src={Bell} alt="bell icon" /> */}
          <img className="w-4 h-7 ml-[20.67px] mr-2" src={Hand} alt="hand icon" />
          <div className="flex items-center font-sans text-base leading-7 tracking-normal text-left">
            <p className="font-bold ">{userPresentedName(authenticatedUserData)}</p>
            <div className="flex items-center w-12 h-12 ml-2 bg-white rounded-full shadow-round font-bold ">
              <RoundPicture
                divStyle="w-11 h-11 m-auto"
                imgStyle='object-cover'
                alt="profile"
                id={`user-id-${authenticatedUserData.id}`}
                src={authenticatedUserData.face_picture?.url ? authenticatedUserData.face_picture.url : ProfileAvatar}
              />
            </div>
            <HeaderMenu
              userData={authenticatedUserData}
            />
          </div>
        </div>
      </div>
      {sessionData?.role === 'admin' && (menuOrigin['people'] || menuOrigin['profile']) &&
        <FinancialAdditions
          openNewDrawer={() => {
            if (menuOrigin['profile']) {
              handleDrawersOpen(selectedPerson, 'profile')
            }
          }}
        />
      }
      {selectedPerson && openedDrawers['profile'] && (
        <ProfileDrawer
          authenticatedUser={sessionData}
          user={selectedPerson || userDataEditForms}
        />
      )}
      {openUserEditForms && userDataEditForms && (
        <EditUser
          user={userDataEditForms}
          closeDrawers={() => {
            setOpenUserEditForms(false)
            setUserDataEditForms(null)
          }}
          openDrawers={(tabName) => {
            if (menuOrigin['profile']) {
              handleDrawersOpen(userDataEditForms, 'profile')
              if (tabName) { setProfileTabName(tabName) }
              setRefreshUserData(true)
              setUserDataEditForms(null)
            }
          }}
          setShowMessage={setShowMessage}
        />
      )}
      {openNewUserForm && (
        <NewUser
          setOpenNewUserForm={setOpenNewUserForm}
          setShowMessage={setShowMessage}
          openDrawers={() => setRefreshUserData(true)}
        />
      )}
      {
        showMessage.success && <SuccessMessage
                                  username={showMessage.userName}
                                  source={showMessage.source}
                                  setShowMessage={setShowMessage}
                                  additionalData={showMessage.additionalData}
                                />
      }
    </>
  )
}
