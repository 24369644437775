import React from "react";
import Field from "./Field";

export default function DateInput({ 
  model,
  name,
  title,
  requiredField,
  style,
  classStyle,
  inviteFieldStyle,
  shouldHide,
  disabled,
  ...props
}) {
  return (
    <div id={`${model}_${name}`} style={style}>
      <Field
        {...props}
        inviteFieldStyle={inviteFieldStyle}
        model={model}
        label={
          <div className={`${shouldHide ? "w-0 h-0" : "" }`}>
            {shouldHide ? <></> : title}

            { requiredField && (
              <span 
                className="pl-1 text-red-500"
                id="required-field"
              >
                *
              </span>
            )}
          </div>
        }
        id={`${model}_${name}_input`}
        name={name}
        disabled={disabled}
        type={props.type ? props.type : "date"}
        className={`${inviteFieldStyle ? `${inviteFieldStyle} text-placeholder-gray` : "py-2 px-3 mt-2 text-md block w-[102%] bg-white focus:bg-white focus:outline-none focus:ring-2 appearance-none disabled:bg-border-gray disabled:text-cyan-blue-medium-dark border border-solid border-border-gray box-border rounded focus:border-gray-600 h-11"} ${shouldHide ? 'w-0 h-0 border-none focus:border-none' : ''} ${classStyle}`}
      />
    </div>
  );
}
