import React from 'react';
import PTBRDateFormat from '#services/PTBRDateFormat';
import i18n from '#translate/i18n';

export default function PersonalData({ row }) {
  const userData = row.original ? row.original : {}
  const id = userData.id
  return (
    <div className='flex items-center h-[180px] pt-2'>
      <div className='flex-shrink-0 h-12 w-12 pl-[5px]'>
        <img
          className='box-border w-10 h-10 border-2 border-solid border-white rounded-full
          drop-shadow-[0.5px_0.5px_2px_rgba(0,0,0,0.25)] absolute z-[100]'
          src={userData.current_profile_url}
          alt='user_profile_picture'
        />
      </div>
      <div className='ml-[15px] h-full'>
        <div id={`report_full_name_user_${id}`} className='h-5 font-sans not-italic font-semibold text-sm text-cyan-blue-medium-dark'>{userData.full_name}</div>
        <div>
          <span className='h-[18px] font-sans not-italic font-semibold text-xs text-cyan-blue-medium-dark'>{i18n.t('reportDashboard.payroll.personal_data.birth_date')} </span>
          <span id={`report_birth_date_user_${id}`} className='font-sans not-italic font-normal text-xs text-[#96A0AA]'>{PTBRDateFormat(userData.birth_date)}</span>
        </div>
        <div>
          <span className='h-[18px] font-sans not-italic font-semibold text-xs text-cyan-blue-medium-dark'>{i18n.t('reportDashboard.payroll.personal_data.rg')} </span>
          <span id={`report_rg_user_${id}`} className='font-sans not-italic font-normal text-xs text-[#96A0AA]'>{userData.rg} </span>
        </div>
        <div>
          <span className='h-[18px] font-sans not-italic font-semibold text-xs text-cyan-blue-medium-dark'>{i18n.t('reportDashboard.payroll.personal_data.cpf')} </span>
          <span id={`report_cpf_user_${id}`} className='font-sans not-italic font-normal text-xs text-[#96A0AA]'>{userData.cpf}</span>
        </div>
        <div>
          <span className='h-[18px] font-sans not-italic font-semibold text-xs text-cyan-blue-medium-dark'>{i18n.t('reportDashboard.payroll.personal_data.hiring_date')} </span>
          <span id={`report_hiring_date_user_${id}`} className='font-sans not-italic font-normal text-xs text-[#96A0AA]'>{PTBRDateFormat(userData.hiring_date)}</span>
        </div>
        <div>
          <span className='h-[18px] font-sans not-italic font-semibold text-xs text-cyan-blue-medium-dark'>{i18n.t('reportDashboard.payroll.personal_data.occupation')} </span>
          <span id={`report_occupation_user_${id}`} className='font-sans not-italic font-normal text-xs text-[#96A0AA]'>
            {userData.occupation ? i18n.t(`work_field.${userData.occupation}`) : ''}
          </span>
        </div>
        <div>
          <span id={`report_corporate_email_user_${id}`} className='font-sans not-italic font-medium text-xs text-[#96A0AA]'>{userData.corporate_email}</span>
        </div>
      </div>
    </div>
  );
}
