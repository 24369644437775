import React from "react";
import DivisionLine from "#components/formComponents/DivisionLine";
import UploadFile from "#components/uploadFile/UploadFile";
import docIcon from "#images/doc-icon.svg";
import i18n from "#translate/i18n";

export default function PersonalDocumentForm({ selectedForm }) {
  const model = "user";
  const hidden = selectedForm === "personal-document-header" ? "" : "hidden";

  return (
    <div id="personal-documents" className={ hidden }>
      <div>
        <span className="text-title-gray font-sans font-semibold text-sm mb-5">
          {i18n.t("personal_document.titles.documents").toUpperCase()}
        </span>

        <p className="text-sm mb-2 leading-6 -tracking-[0.016rem]">
          {i18n.t("personal_document.descriptions.documents")}
        </p>

        <div className="grid grid-cols-4">
          <UploadFile
            model={model}
            id="rg_front"
            name="rg_front"
            documentImage={docIcon}
            documentType={i18n.t("personal_document.document_type.rg_front")}
            requiredField={ true }
            alt={i18n.t("personal_document.alt.rg_front")}
            customClass={""}
            isBox
          />

          <UploadFile
            model={model}
            id="rg_back"
            name="rg_back"
            documentImage={docIcon}
            documentType={i18n.t("personal_document.document_type.rg_back")}
            requiredField={ true }
            alt={i18n.t("personal_document.alt.rg_back")}
            customClass={""}
            isBox
          />

          <UploadFile
            model={model}
            id="cpf_front"
            name="cpf_front"
            documentImage={docIcon}
            documentType={i18n.t("personal_document.document_type.cpf_front")}
            requiredField={ true }
            alt={i18n.t("personal_document.alt.cpf_front")}
            isBox
          />

          <UploadFile
            model={model}
            name="cpf_back"
            id="cpf_back"
            documentImage={docIcon}
            documentType={i18n.t("personal_document.document_type.cpf_back")}
            requiredField={ true }
            alt={i18n.t("personal_document.alt.cpf_back")}
            isBox
          />
        </div>
      </div>

      <DivisionLine />

      <div>
        <span className="text-title-gray font-sans font-semibold text-sm mb-5">
          {i18n.t("personal_document.titles.residence_proof").toUpperCase()}
        </span>

        <p className="text-sm mb-2 leading-6 -tracking-[0.016rem]">
          {i18n.t("personal_document.descriptions.residence_proof")}
        </p>

        <div className="grid grid-cols-4">
          <UploadFile
            model={model}
            id="residence_proof"
            name="residence_proof"
            documentImage={docIcon}
            documentType={i18n.t(
              "personal_document.document_type.residence_proof"
            )}
            requiredField={ true }
            alt={i18n.t("personal_document.alt.residence_proof")}
            customClass={""}
            isBox
          />
        </div>
      </div>

      <DivisionLine />

      <div>
        <span className="text-title-gray font-sans font-semibold text-sm mb-5">
          {i18n.t("personal_document.titles.mei_cnpj").toUpperCase()}
        </span>

        <p className="text-sm mb-2 leading-6 -tracking-[0.016rem]">
          {i18n.t("personal_document.descriptions.mei_cnpj")}
        </p>

        <div className="grid grid-cols-4">
          <UploadFile
            model={model}
            id="cnpj_card"
            name="cnpj_card"
            documentImage={docIcon}
            documentType={i18n.t("personal_document.document_type.cnpj_card")}
            alt={i18n.t("personal_document.alt.cnpj_card")}
            isBox
          />
        </div>
      </div>
    </div>
  );
}
