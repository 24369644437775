/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import i18n from '#translate/i18n';
import DateInput from '#components/formComponents/DateInput';
import addDays from '#services/addDays'

export default function LoadedReturnDateInput({ model, registeredAllowances, fieldName, allowanceName, referenceDate, editObjectId }) {
  const { setValue } = useFormContext() || {};
  const watchStartDate = useWatch({ name: `${model}.${referenceDate}` });
  const [lastStartDate, setLastStartDate] = useState('')
  const [lastReturnDate, setLastReturnDate] = useState('')

  useEffect(() => {
    const currentAllowance = registeredAllowances.find(({ name }) => name === allowanceName)
    if(watchStartDate && currentAllowance) {
      const daysToAdd = currentAllowance.attributes.total_calendar_days + 1
      const returnDate = addDays(watchStartDate, daysToAdd)
      setValue(`${model}.${fieldName}`, returnDate.toJSON().split('T')[0])
      if(!editObjectId || editObjectId !== currentAllowance.attributes.last_id) {
        setLastReturnDate(currentAllowance.attributes.last_return_date)
        setLastStartDate(currentAllowance.attributes.last_start_date)
      }
    }
  }, [watchStartDate]);

  return (
    <>
      <DateInput
        model={model}
        name={referenceDate}
        title={i18n.t(`${model}.${referenceDate}`)}
        classStyle={'w-full'}
        requiredField={true}
      />
      <DateInput
        model={model}
        name={fieldName}
        title={i18n.t(`${model}.${fieldName}`)}
        disabled={true}
        inputstyle={{ color: 'rgba(150, 160, 170, 1)', backgroundColor: 'rgba(212, 219, 223, 1)' }}
        classStyle={'w-full'}
      />
      <input name={`${model}.last_start_date`} type='hidden' id={`${model}_last_start_date_input`} value={lastStartDate || ''} />
      <input name={`${model}.last_return_date`} type='hidden' id={`${model}_last_return_date_input`} value={lastReturnDate || ''} />
    </>
  );
}
